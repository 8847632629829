import { getLocaleDateTimeString } from "@whyuz/utils";
import { Timeline } from "flowbite-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FlowbiteTimelineIconTheme, FlowbiteTimelinePointIconTheme } from "..";

export interface ApprovalFlowExecutionHistoryItemProps extends React.PropsWithChildren {
  iconTheme?: FlowbiteTimelinePointIconTheme;
  icon: React.FC<React.SVGProps<SVGSVGElement>> | undefined;
  date: Date;
}

export const TimelineItemLocaleDate = ({
  icon,
  iconTheme = FlowbiteTimelineIconTheme.primaryIcon,
  date,
  children,
}: ApprovalFlowExecutionHistoryItemProps) => {
  const { i18n } = useTranslation();

  return (
    <Timeline.Item>
      <Timeline.Point
        theme={{
          marker: {
            icon: iconTheme,
          },
        }}
        icon={icon}
      />
      <Timeline.Content>
        <Timeline.Time>{getLocaleDateTimeString(i18n.language, date, { month: "long" }, true)}</Timeline.Time>
        <Timeline.Body>{children}</Timeline.Body>
      </Timeline.Content>
    </Timeline.Item>
  );
};

import { AssignedReward, BenefitRewardType } from "@whyuz/services";
import { useTranslation } from "react-i18next";
import { Card, Banner } from "@whyuz/components";
import { useBenefitRewardTypesTranslations } from "@whyuz/app-components";
import { GiftIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";

export interface CollabRewardsProps {
  assignedReward: AssignedReward;
  title: string;
}

export const CollaborationsRewards = ({ assignedReward, title }: CollabRewardsProps) => {
  const { t: tBenefitRewards } = useTranslation("benefitReward");
  const rewardsTranslations = useBenefitRewardTypesTranslations();

  const getName = useMemo(() => {
    switch (assignedReward.type) {
      case BenefitRewardType.Discount:
      case BenefitRewardType.CustomReward:
      case BenefitRewardType.CustomBenefit:
        return assignedReward.name ?? "";
      case BenefitRewardType.GiftCard:
        return rewardsTranslations[BenefitRewardType.GiftCard].name;
      default:
        return "";
    }
  }, [assignedReward, rewardsTranslations]);
  return (
    <Card className="w-full mb-2">
      <div className="flex flex-col w-full space-y-2">
        <Card.Header
          title={title}
          titleStyles="text-primary-600 dark:text-primary-600 font-semibold"
          icon={<GiftIcon className="w-8 h-8 text-primary-600" />}></Card.Header>
        <div className="w-full flex flex-col space-y-2 p-4">
          <h1 className="font-bold pl-2 dark:text-white">{getName}</h1>
          {assignedReward.additionalInfo && assignedReward.additionalInfo !== "" && (
            <Banner>
              <Banner.Title title={tBenefitRewards("description")} />
              <div className="text-xs">{assignedReward.additionalInfo}</div>
            </Banner>
          )}
        </div>
      </div>
    </Card>
  );
};

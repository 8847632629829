import { ApprovalFlowType, GQLError } from "@whyuz/services";
import { AddUserProps } from "./ApprovalFlowStep.tsx";
import { ErrorFormMessage } from "@whyuz/components";
import { ApprovalFlowAddUserEmailInput } from "./ApprovalFlowAddUserEmailInput.tsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatUserName, UserCombobox } from "../../../Users";

export type UserAdditionAvailableWays = "full-usercombobox" | "only-users-usercombobox" | "usercombobox-not-allowed";

export interface ApprovalFlowUserComponentProps {
  approvalFlowType: ApprovalFlowType;
  addUser: (user: AddUserProps) => void;
  errors: GQLError | undefined;
  stepId: string;
}

export const ApprovalFlowUserComponent = ({
  approvalFlowType,
  stepId,
  addUser,
  errors,
}: ApprovalFlowUserComponentProps) => {
  const { t: tApproval } = useTranslation("approval");
  const { t } = useTranslation();
  const addUserMethodAllowed: UserAdditionAvailableWays = useMemo(() => {
    switch (approvalFlowType) {
      case ApprovalFlowType.SuccessCaseExecution:
        return "full-usercombobox";
      case ApprovalFlowType.SuccessCaseContents:
        return "full-usercombobox";
      case ApprovalFlowType.SuccessCaseCustomerParticipation:
        return "usercombobox-not-allowed";
      case ApprovalFlowType.SuccessCaseCustomerContents:
        return "usercombobox-not-allowed";
      default:
        return "full-usercombobox";
    }
  }, [approvalFlowType]);

  return (
    <>
      {addUserMethodAllowed !== "usercombobox-not-allowed" ? (
        <div className="flex flex-col">
          <UserCombobox
            id="user"
            label={tApproval("askforapprovalto")}
            placeholder={tApproval("searchuser")}
            disabled={false}
            addTextOption={addUserMethodAllowed === "full-usercombobox"}
            error={undefined}
            onManualText={(email: string) => {
              if (addUserMethodAllowed === "full-usercombobox") {
                addUser({
                  userId: undefined,
                  name: undefined,
                  email,
                  stepId: stepId,
                });
              }
            }}
            onChange={(user) => {
              addUser({
                userId: user?.id as string,
                name: formatUserName(user),
                email: user?.mail,
                stepId: stepId,
              });
            }}
          />
          {errors && <ErrorFormMessage name="userId" errors={{ formErrors: {}, GQLerrors: errors }} />}
        </div>
      ) : (
        <ApprovalFlowAddUserEmailInput
          id="userId"
          errors={errors}
          placeholder={t("word.email")}
          onChange={(value) =>
            addUser({
              userId: undefined,
              name: undefined,
              email: value,
              stepId: stepId,
            })
          }
        />
      )}
    </>
  );
};

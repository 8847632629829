import {
  FlowbiteModalThemes,
  ModalCommonHeader,
  Page,
  TextEditor,
  useTextEditor,
  useVariablesSuggestionExtension,
  VariableSuggestionType,
} from "@whyuz/components";
import {
  Error_Code,
  GQLError,
  GQLFieldErrorMessageDictionary,
  Language,
  MailTemplate,
  MailTemplateLanguage,
  MailTemplateLanguageInput,
  MailTemplateType,
  useUpdateMailTemplateLanguageMutation,
} from "@whyuz/services";
import { isEmpty, notifyError } from "@whyuz/utils";
import { Button as FlowbiteButton, Modal, ModalProps } from "flowbite-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageCombobox } from "../../Languages/components/LanguageCombobox.tsx";
import { MailTemplateCombobox } from "../MailTemplateCombobox.tsx";
import { useMailTemplateVariablesToSuggest } from "../MailTemplateTypes.tsx";

interface MailTemplateLanguageModalAddOrEditProps extends ModalProps {
  mailTemplateLanguage?: MailTemplateLanguage;
  onClose: () => void;
  onUseTemplate: (mailTemplateLanguage: MailTemplateLanguage) => void;
  onEditTemplate: (mailTemplateLanguage: MailTemplateLanguage) => void;
  onDeleteTemplate: () => void;
  mailTemplateType: MailTemplateType;
}

const getFormData = (mailTemplateLanguage?: MailTemplateLanguage): MailTemplateLanguageInput => ({
  subject: mailTemplateLanguage?.subject || "",
  body: mailTemplateLanguage?.body || "",
  languageId: mailTemplateLanguage?.language?.id || undefined,
  mailTemplateId: (mailTemplateLanguage?.mailTemplate?.id as string) || undefined,
});

type MailTemplateAndUsedLanguages = {
  mailTemplateId?: string;
  name?: string;
  usedLanguages: Language[];
  type?: MailTemplateType;
};

const getInitialMailTemplate = (mailTemplateLanguage?: MailTemplateLanguage): MailTemplateAndUsedLanguages => ({
  mailTemplateId: mailTemplateLanguage?.mailTemplate?.id as string,
  name: mailTemplateLanguage?.mailTemplate?.name as string,
  usedLanguages: mailTemplateLanguage?.mailTemplate?.mailTemplateLanguages?.map((el) => el?.language as Language) || [],
});

export const MailTemplateLanguageModalEdit = ({
  mailTemplateLanguage,
  onClose,
  onDeleteTemplate,
  onEditTemplate,
  onUseTemplate,
  mailTemplateType,
  ...props
}: MailTemplateLanguageModalAddOrEditProps) => {
  const { t } = useTranslation();
  const { t: tMailTemplate } = useTranslation("mailTemplate");
  const [formData, setFormData] = useState<MailTemplateLanguageInput>(getFormData(mailTemplateLanguage));
  const [error, setError] = useState<GQLError>();
  const [edit, setEdit] = useState<boolean>(false);
  const [internalLanguage, setInternalLanguage] = useState<MailTemplateLanguage | undefined>(mailTemplateLanguage);
  const [selectedMailTemplate, setSelectedMailTemplate] = useState<MailTemplateAndUsedLanguages>(
    getInitialMailTemplate(mailTemplateLanguage),
  );
  const [updateMailTemplateLanguageMutation] = useUpdateMailTemplateLanguageMutation();

  const mailTemplateVariablesSuggestions = useMailTemplateVariablesToSuggest();

  const variablesToSuggest: VariableSuggestionType[] = useMemo(
    () => mailTemplateVariablesSuggestions[mailTemplateType],
    [mailTemplateType, mailTemplateVariablesSuggestions],
  );
  const variablesSuggestionExtension = useVariablesSuggestionExtension(variablesToSuggest);
  const mailEditor = useTextEditor({
    content: mailTemplateLanguage?.body,
    additionalExtensions: [variablesSuggestionExtension],
    uploadPublicFiles: true,
  });

  const validateFields = (): boolean => {
    const validationErrors: GQLFieldErrorMessageDictionary = {};
    setError(() => undefined);

    if (isEmpty(formData.languageId)) {
      validationErrors["languageId"] = {
        entity: "mailTemplateLanguage",
        field: "languageId",
        error: Error_Code.RequiredField,
      };
    }
    if (isEmpty(formData.subject)) {
      validationErrors["subject"] = {
        entity: "mailTemplateLanguage",
        field: "subject",
        error: Error_Code.RequiredField,
      };
    }
    if (!selectedMailTemplate.mailTemplateId) {
      validationErrors["mailTemplateCombobox"] = {
        entity: "mailTemplateLanguage",
        field: "mailTemplateCombobox",
        error: Error_Code.RequiredField,
      };
    }

    const errorsFound = Object.keys(validationErrors).length > 0;
    if (errorsFound) {
      const validationError: GQLError = {
        message: t("errors.validationfailed"),
        fieldErrors: validationErrors,
        isUncontrolledError: false,
      };
      setError(() => validationError);
    }
    return !errorsFound;
  };

  const onSaveLanguage = () => {
    if (validateFields()) {
      updateMailTemplateLanguageMutation({
        variables: {
          id: mailTemplateLanguage?.id as string,
          mailTemplateLanguage: {
            ...formData,
            mailTemplateId: selectedMailTemplate.mailTemplateId,
            body: mailEditor?.getHTML(),
          },
        },
      })
        .then((res) => {
          setFormData(getFormData(res));
          setInternalLanguage(res);
          setSelectedMailTemplate({
            mailTemplateId: res.mailTemplate?.id as string,
            name: res.mailTemplate?.name as string,
            usedLanguages: res.mailTemplate?.mailTemplateLanguages?.map((el) => el?.language as Language) || [],
          });
          onEditTemplate(res);
          setEdit(false);
        })
        .catch((updateError: GQLError) => {
          setError(updateError);
          notifyError(tMailTemplate("errors.updatingmailtemplatelanguage"));
        });
    }
  };

  const onChangeMailTemplate = (mailTemplate: MailTemplate | null) => {
    if (mailTemplate) {
      setSelectedMailTemplate({
        mailTemplateId: mailTemplate.id as string,
        name: mailTemplate.name as string,
        usedLanguages: mailTemplate.mailTemplateLanguages?.map((el) => el?.language as Language) || [],
        type: mailTemplate?.type as MailTemplateType,
      });

      if (mailTemplate.id === mailTemplateLanguage?.mailTemplate?.id) {
        setFormData((prev) => ({ ...prev, language: mailTemplateLanguage?.language as Language }));
      } else {
        setFormData((prev) => ({ ...prev, language: undefined }));
      }
    }
  };

  const discardChanges = () => {
    setFormData(getFormData(mailTemplateLanguage));
    setEdit(false);
    setSelectedMailTemplate(getInitialMailTemplate(mailTemplateLanguage));
  };

  return (
    <Modal popup {...props} size="5xl" onClose={onClose} theme={FlowbiteModalThemes.complete}>
      <ModalCommonHeader
        title={`${mailTemplateLanguage?.mailTemplate?.name} - ${mailTemplateLanguage?.language?.name}`}
        edit={edit}
        onEditButtonClicked={() => {
          setEdit(true);
        }}
        onSaveButtonClicked={onSaveLanguage}
        onDiscardChanges={discardChanges}
      />

      <Modal.Body>
        <div className="space-y-2">
          <MailTemplateCombobox
            id={"mailTemplateId"}
            selectedItemId={selectedMailTemplate.mailTemplateId as string}
            label={tMailTemplate("mailtemplate")}
            placeholder={tMailTemplate("mailtemplate")}
            disabled={!edit}
            addTextOption={false}
            onChange={onChangeMailTemplate}
            error={error}
            mailTemplateType={mailTemplateType}
          />
          <div className="flex flex-wrap">
            <div className="flex-auto min-w-[10rem]">
              <LanguageCombobox
                id="languageId"
                selectedItemId={formData.languageId as string}
                placeholder={t("word.language")}
                error={error}
                onChange={(language: Language | null) => {
                  setFormData({ ...formData, languageId: language?.id });
                }}
                label={t("word.language")}
                disabled={!edit}
              />
            </div>
          </div>
        </div>
        <div className="min-w-[18rem] mt-4">
          <Page.FormTextAreaField
            label={tMailTemplate("subject")}
            id="mailTemplateLanguagesSubject"
            error={error}
            onChange={(e) => setFormData({ ...formData, subject: e })}
            value={formData.subject ?? ""}
            disabled={!edit || !selectedMailTemplate.name}
          />
        </div>
        <div className="mt-4 max-h-[40vh] h-full">
          <TextEditor editor={mailEditor} disabled={!edit || !selectedMailTemplate.name}>
            <TextEditor.ContentEditorWithMenu className="min-h-[200px]" label={tMailTemplate("body")} />
          </TextEditor>
        </div>
        {internalLanguage && !edit && (
          <FlowbiteButton
            size="md"
            color="primary"
            className="flex px-0 w-full mt-4"
            onPointerDown={() => {
              onUseTemplate(internalLanguage);
              onClose();
            }}
            type="button">
            {t("buttons.usetemplate")}
          </FlowbiteButton>
        )}
      </Modal.Body>
    </Modal>
  );
};

import { graphql } from "../../codegen/gql";

const addMailTemplate = graphql(`
  mutation addMailTemplate($mailTemplate: MailTemplateInput!) {
    addMailTemplate(mailTemplate: $mailTemplate) {
      id
      name
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      enabled
      type
      mailTemplateLanguages {
        id
        language {
          id
          name
          flagCode
          es
          it
          fr
          de
          pt
        }
        subject
        body
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        mailTemplate {
          id
          name
        }
      }
    }
  }
`);

const updateMailTemplate = graphql(`
  mutation updateMailTemplate($id: UUID!, $mailTemplate: MailTemplateInput!) {
    updateMailTemplate(id: $id, mailTemplate: $mailTemplate) {
      id
      name
      type
      mailTemplateLanguages {
        id
        language {
          id
          name
          flagCode
          es
          it
          fr
          de
          pt
        }
        subject
        body
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        mailTemplate {
          id
          name
        }
      }
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      enabled
    }
  }
`);

const deleteMailTemplate = graphql(`
  mutation deleteMailTemplate($id: UUID!) {
    deleteMailTemplate(id: $id) {
      id
      name
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      enabled
      type
    }
  }
`);

const mailTemplates = graphql(`
  query mailTemplates(
    $filterExpression: FilterExpression
    $page: Int
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    mailTemplates(
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        name
        type
        mailTemplateLanguages {
          id
          language {
            id
            name
            flagCode
            es
            de
            it
            fr
            pt
          }
          subject
          body
          createdOn
          updatedOn
          createdBy
          updatedBy
          deleted
          mailTemplate {
            id
            name
          }
        }
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        enabled
      }
    }
  }
`);

const mailTemplate = graphql(`
  query mailTemplate($id: UUID!) {
    mailTemplate(id: $id) {
      id
      name
      type
      mailTemplateLanguages {
        id
        language {
          id
          name
          flagCode
          es
          de
          it
          fr
          pt
        }
        subject
        body
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        mailTemplate {
          name
          id
        }
      }
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      enabled
    }
  }
`);

export const MailTemplateGQL = {
  query: { mailTemplates, mailTemplate },
  mutation: { addMailTemplate, updateMailTemplate, deleteMailTemplate },
};

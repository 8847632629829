import { graphql } from "../../codegen/gql";

const collaborations = graphql(`
  query collaborations(
    $filterExpression: FilterExpression
    $page: Int
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    collaborations(
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        entityType
        entityId
        title
        description
        status
        tenant {
          id
          name
          logoAssetToken
        }
        inviterName
        customerName
        customerLogoAssetToken
        rewardName
        rewardDescription
        startDate
        endDate
      }
    }
  }
`);

const collaboration = graphql(`
  query collaboration($id: UUID!) {
    collaboration(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      entityType
      entityId
      title
      description
      status
      tenant {
        id
        name
        logoAssetToken
      }
      inviterName
      customerName
      customerLogoAssetToken
      rewardName
      rewardDescription
      startDate
      endDate
    }
  }
`);

const collaborationByToken = graphql(`
  query collaborationByToken($token: String!) {
    collaborationByToken(token: $token) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      entityType
      entityId
      title
      description
      status
      tenant {
        id
        name
        logoAssetToken
      }
      inviterName
      customerName
      customerLogoAssetToken
      rewardName
      rewardDescription
      startDate
      endDate
    }
  }
`);

export const CollaborationGQL = {
  query: { collaborations, collaboration, collaborationByToken },
  mutation: {},
};

import { FlowbiteModalThemes, Page } from "@whyuz/components";
import { RIGHT_BUTTON } from "@whyuz/data";
import { ApprovalFlowTemplate, ApprovalFlowTemplateStep } from "@whyuz/services";
import { Button as FlowbiteButton, Modal, ModalProps } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { ApprovalFlowTemplateStepCardModal } from "./ApprovalFlowTemplateStepCardModal.tsx";

interface Props extends ModalProps {
  approvalFlowTemplate: ApprovalFlowTemplate;
  onClose: () => void;
  onUseTemplate: (approvalFlowTemplate: ApprovalFlowTemplate) => void;
}

export const OneApprovalFlowTemplateModal = ({ onClose, onUseTemplate, approvalFlowTemplate, ...props }: Props) => {
  const { t } = useTranslation();

  const onUseApprovalFlowTemplate = () => {
    onUseTemplate(approvalFlowTemplate);
  };

  return (
    <Modal popup {...props} onClose={onClose} id="modal-nested" size="4xl" theme={FlowbiteModalThemes.complete}>
      <Modal.Header>
        <Page.Title classname="w-3/5 lg:w-full" title={approvalFlowTemplate.name as string} />
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col w-full h-full md:h-[60vh] items-start">
          <div className="overflow-hidden overflow-y-auto flex-1 w-full">
            {approvalFlowTemplate.steps &&
              approvalFlowTemplate.steps.map((item) => (
                <ApprovalFlowTemplateStepCardModal
                  key={item?.id as string}
                  approvalFlowTemplateStep={item as ApprovalFlowTemplateStep}
                />
              ))}
          </div>
          <FlowbiteButton
            size="md"
            color="primary"
            className="mt-4 flex px-0 w-full"
            onPointerDown={(e: React.PointerEvent) => {
              if (e.button !== RIGHT_BUTTON) {
                onUseApprovalFlowTemplate();
                onClose();
              }
            }}
            type="button">
            {t("buttons.usetemplate")}
          </FlowbiteButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

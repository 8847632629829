import { VideoPlayer } from "@whyuz/components";
import { SuccessCaseContentItem } from "@whyuz/services";
import { useMemo } from "react";
import { API_REST_ENDPOINT } from "@whyuz/data";

export interface SuccessCaseQuestionCardVideoProps {
  question: SuccessCaseContentItem;
}

export const SuccessCaseQuestionCardVideo = ({ question }: SuccessCaseQuestionCardVideoProps) => {
  const asset = useMemo(() => {
    return question.assets && question.assets[0] ? question.assets[0] : undefined;
  }, [question]);

  return (
    <div className="p-5 w-[35%]">
      {asset && asset.accessToken && (
        <VideoPlayer src={API_REST_ENDPOINT.ASSETS + "?token=" + asset?.accessToken} showControlsOnHover={false} />
      )}
    </div>
  );
};

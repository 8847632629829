import { Button, Error404PageNotFound, Page } from "@whyuz/components";
import { useSearchParams } from "react-router-dom";
import { WHYUZ_URL_PARAMETERS } from "@whyuz/data";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import {
  ApprovalFlowExecutionStatus,
  ApprovalFlowExecutionUser,
  ApprovalFlowStepExecutionStatus,
  ApprovalFlowType,
  GQLError,
  GQLErrorStatusCode,
  useApprovalFlowExecutionUserLazyQuery,
  useApproveExecutionUserMutation,
  useDeclineExecutionUserMutation,
  useUser,
} from "@whyuz/services";
import { useTranslation } from "react-i18next";
import { CheckBadgeIcon, HandThumbUpIcon } from "@heroicons/react/24/outline";
import {
  ApprovalFlowExecutionStepAndUserStatusBadge,
  ModalRejectOrCancelApprovalFlowExecution,
  useApprovalFlowTypesTranslations,
} from "@whyuz/app-components";
import { notifyError, notifyInfo } from "@whyuz/utils";
import { CollaborationSuccessCaseManager } from "../SuccessCase";

export interface DecodedToken {
  tenantId: string;
  userLicenseId: string;
  approvalExecutionUserId: string;
  entityId: string;
  approvalExecutionType: ApprovalFlowType;
}

export const UserApprovalManager = () => {
  const { t: tApproval } = useTranslation("approval");
  const { t } = useTranslation();
  const userCtx = useUser();
  const [searchParams] = useSearchParams();
  const token = searchParams.get(WHYUZ_URL_PARAMETERS.TOKEN);
  const [tokenDecoded, setTokenDecoded] = useState<DecodedToken | undefined>();
  const [error, setError] = useState<GQLError>();
  const [approvalFlowExecutionUser, setApprovalFlowExecutionUser] = useState<ApprovalFlowExecutionUser>();
  const [approvalFlowExecutionUserLazyQuery, { hasEverBeenExecuted, isLoading }] =
    useApprovalFlowExecutionUserLazyQuery();
  const [approveUserMutation] = useApproveExecutionUserMutation();
  const [declineUserMutation] = useDeclineExecutionUserMutation();
  const approvalTypes = useApprovalFlowTypesTranslations();
  useEffect(() => {
    if (token) {
      const decodedToken: DecodedToken = jwtDecode(token);
      setTokenDecoded(decodedToken);
      approvalFlowExecutionUserLazyQuery({
        variables: { id: decodedToken.approvalExecutionUserId, tenantId: decodedToken.tenantId },
      })
        .then((res) => {
          res
            ? setApprovalFlowExecutionUser(res)
            : setError({
                isUncontrolledError: true,
                statusCode: GQLErrorStatusCode.ENTITY_NOT_FOUND,
                message: tApproval("approval") + " " + String(decodedToken.approvalExecutionUserId),
                fieldErrors: {},
              });
        })
        .catch((error: GQLError) => setError(error));
    }
  }, [approvalFlowExecutionUserLazyQuery, tApproval, token]);

  const acceptApprovalFlow = () => {
    approveUserMutation({
      variables: { approvalFlowUserId: approvalFlowExecutionUser?.id as string, tenantId: tokenDecoded?.tenantId },
    })
      .then((res) => {
        setApprovalFlowExecutionUser(res);
        notifyInfo(tApproval("userapproval.approvedsuccessfully"));
      })
      .catch(() => {
        notifyError(tApproval("userapproval.approvingerror"));
      });
  };

  const declineApprovalFlow = (reason: string) => {
    declineUserMutation({
      variables: {
        approvalFlowUserId: approvalFlowExecutionUser?.id as string,
        reason,
        tenantId: tokenDecoded?.tenantId,
      },
    })
      .then((res) => {
        setApprovalFlowExecutionUser(res);
        notifyInfo(tApproval("userapproval.rejectedsuccessfully"));
      })
      .catch(() => {
        notifyError(tApproval("userapproval.rejectingerror"));
      });
  };

  if (
    (hasEverBeenExecuted &&
      approvalFlowExecutionUser &&
      !userCtx.isUserSuperAdmin &&
      approvalFlowExecutionUser.deleted) ||
    !token
  ) {
    return <Error404PageNotFound />;
  }

  return (
    <Page.LoadingOrError isLoading={isLoading} error={error}>
      {((approvalFlowExecutionUser?.approvalFlowExecutionStep?.approvalFlowExecution?.type as ApprovalFlowType) ===
        ApprovalFlowType.SuccessCaseCustomerParticipation ||
        (approvalFlowExecutionUser?.approvalFlowExecutionStep?.approvalFlowExecution?.type as ApprovalFlowType) ===
          ApprovalFlowType.SuccessCaseCustomerContents) && (
        <>
          <Page.TitlePanel
            icon={<CheckBadgeIcon className="w-8 h-8 text-primary-600" />}
            title={
              approvalTypes[
                approvalFlowExecutionUser?.approvalFlowExecutionStep?.approvalFlowExecution?.type as ApprovalFlowType
              ].name
            }
            bottomBorder={false}>
            <ApprovalFlowExecutionStepAndUserStatusBadge
              status={
                approvalFlowExecutionUser?.approvalFlowExecutionStep?.status ===
                ApprovalFlowStepExecutionStatus.ApprovalCancelled
                  ? ApprovalFlowStepExecutionStatus.ApprovalCancelled
                  : (approvalFlowExecutionUser?.status as ApprovalFlowStepExecutionStatus)
              }
            />

            {(approvalFlowExecutionUser?.status as ApprovalFlowStepExecutionStatus) ===
              ApprovalFlowStepExecutionStatus.Pending &&
              approvalFlowExecutionUser?.approvalFlowExecutionStep?.status !==
                ApprovalFlowStepExecutionStatus.ApprovalCancelled && (
                <>
                  <Button
                    leftIcon={<HandThumbUpIcon className="h-4 w-4 stroke-2 self-center" />}
                    classname="mr-2"
                    type="button"
                    size="sm"
                    color="green"
                    breakpointSM={false}
                    onPointerDown={acceptApprovalFlow}>
                    {t("buttons.accept")}
                  </Button>
                  <ModalRejectOrCancelApprovalFlowExecution onReject={declineApprovalFlow} text="decline" />
                </>
              )}
          </Page.TitlePanel>
          <CollaborationSuccessCaseManager
            key={
              (approvalFlowExecutionUser?.approvalFlowExecutionStep?.approvalFlowExecution
                ?.status as ApprovalFlowExecutionStatus) +
              approvalFlowExecutionUser?.approvalFlowExecutionStep?.approvalFlowExecution?.entityId
            }
            token={tokenDecoded}
          />
        </>
      )}
    </Page.LoadingOrError>
  );
};

import { Collaboration } from "@whyuz/services";
import { forwardRef } from "react";
import { CollaborationCardBody } from "./CollaborationCardBody";
import { CollaborationCardHeader } from "./CollaborationCardHeader";

export interface CollaborationCardProps {
  collaboration: Collaboration;
}

export const CollaborationCard = forwardRef<HTMLDivElement, CollaborationCardProps>(
  ({ collaboration }: CollaborationCardProps, ref) => {
    return (
      <div
        ref={ref}
        className="dark:text-white border border-gray-200 dark:border-gray-600 rounded-md shadow-md w-full sm:w-[492px] sm:max-w-[492px]">
        <CollaborationCardHeader collaboration={collaboration} />
        <CollaborationCardBody collaboration={collaboration} />
      </div>
    );
  },
);

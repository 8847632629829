import { Label, Radio, RadioProps } from "flowbite-react";

export const RadioButton = ({ id, children, disabled, ...props }: RadioProps) => {
  return (
    <div className="flex items-center gap-2">
      <Radio id={id} className={disabled ? "cursor-not-allowed" : "cursor-pointer"} {...props} />
      <Label htmlFor={id} className={disabled ? "disabled" : undefined}>
        {children}
      </Label>
    </div>
  );
};

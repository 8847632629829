import { Button, Page, useModal } from "@whyuz/components";
import { ApprovalFlowExecution, useUser } from "@whyuz/services";
import { Modal } from "flowbite-react";
import { useTranslation } from "react-i18next";

interface SuccessCaseContentEditorFinishModalProps {
  onFinish: () => void;
  onNeedContentApproval: () => void;
  successCaseMainContactEmail?: string;
  contentApprovalInCourse?: ApprovalFlowExecution;
}

export const SuccessCaseContentEditorFinishModal = ({
  onFinish,
  onNeedContentApproval,
  successCaseMainContactEmail,
  contentApprovalInCourse,
}: SuccessCaseContentEditorFinishModalProps) => {
  const { t } = useTranslation();
  const userCtx = useUser();
  const { showModal, closeModal, modalProps } = useModal();
  const { t: tSuccessCaseContentEditor } = useTranslation("successcasecontenteditor");

  if (successCaseMainContactEmail !== userCtx.userLicense?.mail) {
    return null;
  }

  return (
    <>
      <Button classname="mr-2" type="button" size="sm" color="primary" breakpointSM={false} onPointerDown={showModal}>
        {tSuccessCaseContentEditor("button.finish")}
      </Button>
      {modalProps.show && (
        <Modal popup size="4xl" {...modalProps}>
          <Modal.Header className="pt-8 px-10">
            <Page.Title title={tSuccessCaseContentEditor("finishmodal.title")} />
          </Modal.Header>
          <Modal.Body>
            <Page.FormContainer>
              {!contentApprovalInCourse && <p>{tSuccessCaseContentEditor("finishmodal.subtitle")}</p>}
              {contentApprovalInCourse && (
                <p>{tSuccessCaseContentEditor("finishmodal.subtitlecontentapprovalalreadyexist")}</p>
              )}
            </Page.FormContainer>
            <Page.PanelFooterContainer className="pt-0">
              <Page.ButtonsContainer className="justify-end">
                <Button
                  color="primary"
                  onPointerDown={() => {
                    onFinish();
                    closeModal();
                  }}>
                  {tSuccessCaseContentEditor("finishmodal.finishbutton")}
                </Button>
                {!contentApprovalInCourse && (
                  <Button
                    color="primary"
                    onPointerDown={() => {
                      onNeedContentApproval();
                      closeModal();
                    }}>
                    {tSuccessCaseContentEditor("finishmodal.needcontentapproval")}
                  </Button>
                )}
                <Button color="gray" onPointerDown={closeModal}>
                  {t("buttons.cancel")}
                </Button>
              </Page.ButtonsContainer>
            </Page.PanelFooterContainer>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

import { AssignedReward, SuccessCase } from "@whyuz/services";
import { isAValidReward } from "@whyuz/app-components";
import { CollaborationsRewards } from "../../components";
import { useTranslation } from "react-i18next";
export interface CollaborationSuccessCaseRewardsProps {
  successCase: SuccessCase;
}

export const CollaborationSuccessCaseRewards = ({ successCase }: CollaborationSuccessCaseRewardsProps) => {
  const { t: tSuccessCase } = useTranslation("successCaseTemplate");
  return (
    <div className="flex flex-col space-y-6">
      {isAValidReward(successCase.customerReward ?? undefined) && (
        <CollaborationsRewards
          assignedReward={successCase.customerReward as AssignedReward}
          title={tSuccessCase("successcasesubforms.rewards.customerreward")}
        />
      )}
      {isAValidReward(successCase.mainContactReward ?? undefined) && (
        <CollaborationsRewards
          assignedReward={successCase.mainContactReward as AssignedReward}
          title={tSuccessCase("successcasesubforms.rewards.contactreward")}
        />
      )}
    </div>
  );
};

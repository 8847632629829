import { Label, RadioProps } from "flowbite-react";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

export type RadioButtonGroupProps = {
  id?: string;
  name: string;
  children: React.ReactNode;
  type?: "flex-row" | "flex-col";
  label?: string;
  onChange: (value: string | number | readonly string[] | undefined) => void;
  disabled?: boolean;
  className?: string;
} & (
  | { initialValue?: undefined; value: string | number | readonly string[] | undefined }
  | {
      initialValue: string | number | readonly string[] | undefined;
      value?: undefined;
    }
);

export const RadioButtonGroup = ({
  id,
  name,
  className,
  children,
  label,
  onChange,
  initialValue,
  value,
  disabled = false,
  type = "flex-col",
}: RadioButtonGroupProps) => {
  const [currentValue, setCurrentValue] = useState<string | number | readonly string[] | undefined>(initialValue);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange && !disabled) {
      setCurrentValue(event.target.value);
      onChange(event.target.value);
    }
  };

  return (
    <fieldset id={id} className={twMerge("max-w-md flex flex-col space-y-3", className)}>
      {label && (
        <Label htmlFor={id} className="text-sm font-semibold text-gray-900 select-none">
          {label}
        </Label>
      )}
      <span id={id} className={twMerge("flex gap-1", type)}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child as React.ReactElement<RadioProps>, {
            disabled,
            name,
            onChange: handleRadioChange,
            defaultChecked: value ? undefined : (child as React.ReactElement<RadioProps>).props.value === currentValue,
            checked: value ? (child as React.ReactElement<RadioProps>).props.value === value : undefined,
          }),
        )}
      </span>
    </fieldset>
  );
};

import { HandThumbUpIcon } from "@heroicons/react/24/outline";
import { Button, ComboboxThemes, TextEditor, useModal, useTextEditor } from "@whyuz/components";
import {
  ApprovalFlowExecution,
  ApprovalFlowType,
  GQLError,
  MailAccountPage,
  MailTemplateLanguage,
  MailTemplateType,
  SortDirection,
  useMailAccountsLazyQuery,
  useUpdateApprovalFlowExecutionMutation,
} from "@whyuz/services";
import { notifyError } from "@whyuz/utils";
import { Modal, Textarea } from "flowbite-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { MailAccountCombobox } from "../../MailAccount";
import { MailTemplatesLanguagesInEmailModal } from "../../MailTemplates";

export interface ApprovalFlowExecutionMailMessageModalProps {
  onAccept: () => void;
  approvalFlow: ApprovalFlowExecution;
  tenantId?: string;
}
export const ApprovalFlowExecutionMailMessageModal = ({
  onAccept,
  approvalFlow,
  tenantId,
}: ApprovalFlowExecutionMailMessageModalProps) => {
  const { t: tApproval } = useTranslation("approval");
  const { t } = useTranslation();
  const [error, setError] = useState<GQLError | undefined>(undefined);
  const [selectedMailAccount, setSelectedMailAccount] = useState<string | undefined>(
    (approvalFlow.mailAccount?.id as string) ?? undefined,
  );
  const { showModal, closeModal, modalProps } = useModal();
  const [hasMailAccount, setHasMailAccount] = useState<boolean>(false);
  const [updateApprovalFlow] = useUpdateApprovalFlowExecutionMutation();
  const [mailAccountsQuery, { isLoading }] = useMailAccountsLazyQuery();
  const [subject, setSubject] = useState<string>(approvalFlow?.subjectHtml as string);

  const mailBodyEditor = useTextEditor({
    content: approvalFlow?.messageHtml,
    uploadPublicFiles: true,
  });
  const allowTemplatesAndMailAccountCombobox: boolean = useMemo(() => {
    return (
      approvalFlow?.type === ApprovalFlowType.SuccessCaseExecution ||
      approvalFlow?.type === ApprovalFlowType.SuccessCaseContents
    );
  }, [approvalFlow?.type]);

  useEffect(() => {
    if (allowTemplatesAndMailAccountCombobox) {
      mailAccountsQuery({
        variables: {
          filterExpression: undefined,
          pageSize: 20,
          sortField: "user",
          sortDirection: SortDirection.Asc,
        },
      })
        .then((page: MailAccountPage) => {
          if (page.content && page.content?.length > 0) {
            setHasMailAccount(true);
          }
        })
        .catch((e) => {
          setError(e as GQLError);
        });
    }
  }, [mailAccountsQuery, allowTemplatesAndMailAccountCombobox]);
  const updateApprovalFlowMail = () => {
    updateApprovalFlow({
      variables: {
        id: approvalFlow?.id as string,
        approvalFlowExecution: {
          name: approvalFlow.name,
          type: approvalFlow.type,
          mailAccountId: selectedMailAccount,
          subjectHtml: subject !== "" ? subject : null,
          messageHtml: mailBodyEditor?.getHTML() === "<p></p>" ? null : mailBodyEditor?.getHTML(),
        },
        tenantId,
      },
    })
      .then(() => {
        onAccept();
        closeModal();
      })
      .catch(() => {
        mailBodyEditor?.commands.setContent(approvalFlow?.messageHtml as string);
        setSubject(approvalFlow?.subjectHtml as string);
        notifyError(tApproval("errors.updatingapprovalflow"));
      });
  };

  const onUseExistingMailTemplate = (mailTemplate: MailTemplateLanguage) => {
    mailBodyEditor?.commands.setContent(mailTemplate.body as string);
    setSubject(mailTemplate.subject as string);
  };

  const handlerOpenModal = () => {
    showModal();
  };

  return (
    <>
      <Button
        leftIcon={<HandThumbUpIcon className="h-4 w-4 stroke-2 self-center" />}
        classname="mr-2"
        type="button"
        size="sm"
        breakpointSM={false}
        onPointerDown={handlerOpenModal}>
        {tApproval("launchapprovalflow")}
      </Button>
      {modalProps.show && (
        <Modal popup size="4xl" {...modalProps}>
          <Modal.Body className="p-0">
            <div className="flex">
              <div
                className={twMerge(
                  "flex flex-col",
                  allowTemplatesAndMailAccountCombobox
                    ? "w-3/5 border-r-2 border-gray-100 dark:border-gray-600"
                    : "w-full",
                )}>
                {hasMailAccount && allowTemplatesAndMailAccountCombobox && !isLoading && (
                  <div className="border-b-2 border-gray-100 dark:border-gray-600 p-3 flex space-x-2">
                    <span className="text-sm text-gray-400">{t("placeholders.from")}</span>
                    <MailAccountCombobox
                      id="successCaseSendInvitationModalMailAccountCombobox"
                      selectedItemId={selectedMailAccount}
                      error={error}
                      theme={ComboboxThemes.clean}
                      showChevron={false}
                      fullWidth={true}
                      onChange={(mailAccount) => setSelectedMailAccount(mailAccount.id as string)}
                    />
                  </div>
                )}
                <div className="border-b-2 border-gray-100 dark:border-gray-600 p-2 flex">
                  <span className="text-sm text-gray-400 py-1">{t("placeholders.subject")}</span>
                  <Textarea
                    id="successCaseSendInvitationSubject"
                    value={subject}
                    autoComplete="off"
                    onChange={(e) => setSubject(e.target.value)}
                    className="border-none bg-transparent focus:ring-0 dark:focus:ring-0 p-0 h-8 pt-1 px-1"
                  />
                </div>
                <div className="p-4">
                  <div className="min-w-[18rem]">
                    <TextEditor editor={mailBodyEditor}>
                      <TextEditor.ContentEditorWithMenu
                        className="max-h-96 min-h-96"
                        actions={["fontStyle", "variables", "textAlignments"]}
                      />
                    </TextEditor>
                  </div>
                </div>
              </div>
              {allowTemplatesAndMailAccountCombobox && (
                <div className="w-2/5 p-2">
                  <div className="font-semibold text-xs my-2">{t("header.templates")}</div>
                  <MailTemplatesLanguagesInEmailModal
                    handleUseTemplate={onUseExistingMailTemplate}
                    mailTemplateType={MailTemplateType.ApprovalSuccessCaseExecution}
                  />
                </div>
              )}
            </div>
            <div className="p-3 flex justify-end space-x-2 shadow-[rgba(0,0,0,0.10)_0px_-1px_5px_0px]">
              <Button color="primary" onPointerDown={updateApprovalFlowMail}>
                {tApproval("launchapprovalflow")}
              </Button>
              <Button color="gray" onPointerDown={closeModal}>
                {t("buttons.cancel")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

import { CollaborationStatus } from "@whyuz/services";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useCollaborationStatusTranslation = (): Record<CollaborationStatus, string> => {
  const { t: tCollaboration } = useTranslation("collaboration");

  return useMemo(() => {
    return {
      [CollaborationStatus.Cancelled]: tCollaboration("status.cancelled"),
      [CollaborationStatus.Completed]: tCollaboration("status.completed"),
      [CollaborationStatus.Declined]: tCollaboration("status.declined"),
      [CollaborationStatus.InProgress]: tCollaboration("status.inprogress"),
      [CollaborationStatus.PendingToBeAccepted]: tCollaboration("status.pendingtobeaccepted"),
      [CollaborationStatus.PendingInternalApproval]: tCollaboration("status.pendinginternalapproval"),
    };
  }, [tCollaboration]);
};

import { Badge } from "flowbite-react";
import { CollaborationStatus } from "@whyuz/services";
import { useCollaborationStatusTranslation } from "../hooks/useCollaborationStatusTranslation.tsx";

interface CollaborationStatusBadgeProps {
  collaborationStatus: CollaborationStatus;
}
export const CollaborationStatusBadge = ({ collaborationStatus }: CollaborationStatusBadgeProps) => {
  const collaborationStatusTranslation = useCollaborationStatusTranslation();

  return (
    <Badge className="flex w-fit text-primary-600" color="blue">
      <div className="whitespace-nowrap"> {collaborationStatusTranslation[collaborationStatus]}</div>
    </Badge>
  );
};

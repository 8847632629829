import "./utils/i18n.ts";
import "./utils/sockJSInit.ts"; // Import needed to initialize sockJS (use before any other import)

import { AppComponentsContextProvider } from "@whyuz/app-components";
import { ErrorBoundary, GenericError } from "@whyuz/components";
import { KEYCLOAK } from "@whyuz/data";
import { KeycloakContextProvider } from "@whyuz/services";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App, AppContextProvider } from "./pages/App/index.ts";

const root = document.getElementById("root");
if (root) {
  ReactDOM.createRoot(root).render(
    <ErrorBoundary fallback={(error) => <GenericError title="Fatal error" description={error.message} />}>
      <KeycloakContextProvider clientId={KEYCLOAK.COLLAB_CLIENT_ID}>
        <React.StrictMode>
          <BrowserRouter>
            <AppComponentsContextProvider>
              <AppContextProvider>
                <App />
              </AppContextProvider>
            </AppComponentsContextProvider>
          </BrowserRouter>
        </React.StrictMode>
      </KeycloakContextProvider>
    </ErrorBoundary>,
  );
}

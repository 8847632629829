import {
  CloudArrowUpIcon,
  DocumentTextIcon,
  PencilSquareIcon,
  PhotoIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { ContentType } from "@whyuz/services";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { SelectMenuItem } from "../SelectMenu";

export interface ContentTypeEntity {
  id: ContentType;
  name: string;
}
export const useSuccessCaseStatusTranslations = (): Record<ContentType, ContentTypeEntity> => {
  const { t } = useTranslation();
  return useMemo(() => {
    return {
      [ContentType.Image]: {
        id: ContentType.Image,
        name: t("contenttypeselect.typeimage"),
      },
      [ContentType.Text]: {
        id: ContentType.Text,
        name: t("contenttypeselect.typetext"),
      },
      [ContentType.Video]: {
        id: ContentType.Video,
        name: t("contenttypeselect.typevideo"),
      },
      [ContentType.Files]: {
        id: ContentType.Files,
        name: t("contenttypeselect.typefiles"),
      },
      [ContentType.Free]: {
        id: ContentType.Free,
        name: t("contenttypeselect.typefree"),
      },
    };
  }, [t]);
};

export const useContentType = (extendedLabel?: boolean): SelectMenuItem<ContentType>[] => {
  const { t } = useTranslation();
  const contentTypeIcon = useContentTypeIcon();
  return useMemo(() => {
    return [
      {
        id: ContentType.Free,
        name: t("contenttypeselect.free"),
        icon: contentTypeIcon[ContentType.Free],
      },
      {
        id: ContentType.Image,
        name: extendedLabel ? "contenttypeselect.typeimage" : t("contenttypeselect.image"),
        icon: contentTypeIcon[ContentType.Image],
      },
      {
        id: ContentType.Text,
        name: extendedLabel ? "contenttypeselect.typetext" : t("contenttypeselect.text"),
        icon: contentTypeIcon[ContentType.Text],
      },
      {
        id: ContentType.Video,
        name: extendedLabel ? "contenttypeselect.typevideo" : t("contenttypeselect.video"),
        icon: contentTypeIcon[ContentType.Video],
      },
      {
        id: ContentType.Files,
        name: extendedLabel ? "contenttypeselect.typefiles" : t("contenttypeselect.files"),
        icon: contentTypeIcon[ContentType.Files],
      },
    ];
  }, [t, contentTypeIcon, extendedLabel]);
};

export const useContentTypeIcon = (iconStyles?: string): Record<ContentType, React.ReactNode> => {
  const iconClass = "h-4 w-4 text-primary-600";
  return useMemo(() => {
    return {
      [ContentType.Video]: <VideoCameraIcon className={twMerge(iconClass, iconStyles)} />,
      [ContentType.Text]: <DocumentTextIcon className={twMerge(iconClass, iconStyles)} />,
      [ContentType.Image]: <PhotoIcon className={twMerge(iconClass, iconStyles)} />,
      [ContentType.Free]: <PencilSquareIcon className={twMerge(iconClass, iconStyles)} />,
      [ContentType.Files]: <CloudArrowUpIcon className={twMerge(iconClass, iconStyles)} />,
    };
  }, [iconStyles]);
};

export interface ContentTypeSizeUnitOfMeasureEntity {
  id: ContentType;
  name: string;
}
export const useContentSizeUnitsOfMeasure = (): Record<ContentType, ContentTypeSizeUnitOfMeasureEntity> => {
  const { t } = useTranslation();

  return useMemo(() => {
    return {
      [ContentType.Free]: {
        id: ContentType.Free,
        name: t("contenttypeselect.contentsizeunitsofmeasure.UNLIMITED"),
      },
      [ContentType.Text]: {
        id: ContentType.Text,
        name: t("contenttypeselect.contentsizeunitsofmeasure.LETTERS"),
      },
      [ContentType.Video]: {
        id: ContentType.Video,
        name: t("contenttypeselect.contentsizeunitsofmeasure.SECONDS"),
      },
      [ContentType.Files]: {
        id: ContentType.Files,
        name: t("contenttypeselect.contentsizeunitsofmeasure.MEGABYTES"),
      },
      [ContentType.Image]: {
        id: ContentType.Image,
        name: t("contenttypeselect.contentsizeunitsofmeasure.MEGABYTES"),
      },
    };
  }, [t]);
};

export const useContentSizeUnitsOfMeasureLimit = (
  limit?: number,
): Record<ContentType, ContentTypeSizeUnitOfMeasureEntity> => {
  const { t } = useTranslation();

  return useMemo(() => {
    return {
      [ContentType.Free]: {
        id: ContentType.Free,
        name: t("contenttypeselect.contentsizeunitsofmeasuresentence.UNLIMITED"),
      },
      [ContentType.Text]: {
        id: ContentType.Text,
        name: t("contenttypeselect.contentsizeunitsofmeasuresentence.LETTERS", {
          limit: limit,
        }),
      },
      [ContentType.Video]: {
        id: ContentType.Video,
        name: t("contenttypeselect.contentsizeunitsofmeasuresentence.SECONDS", {
          limit: limit,
        }),
      },
      [ContentType.Files]: {
        id: ContentType.Files,
        name: t("contenttypeselect.contentsizeunitsofmeasuresentence.MEGABYTES", {
          limit: limit,
        }),
      },
      [ContentType.Image]: {
        id: ContentType.Image,
        name: t("contenttypeselect.contentsizeunitsofmeasuresentence.MEGABYTES", {
          limit: limit,
        }),
      },
    };
  }, [t, limit]);
};

import {CheckIcon} from "@heroicons/react/24/outline";
import {Modal} from "flowbite-react";
import {useTranslation} from "react-i18next";
import { Page } from "../Page";
import { Button } from "../Button";


export interface ModalCommonHeaderProps {
    title:string;
    edit:boolean;
    onEditButtonClicked: () => void;
    onSaveButtonClicked: () => void;
    onDiscardChanges: () => void;
}
export const ModalCommonHeader = ({title, edit, onEditButtonClicked, onSaveButtonClicked, onDiscardChanges}:ModalCommonHeaderProps) => {
    const {t} = useTranslation();
    return (
        <Modal.Header>
            <div className="w-full flex">
                <Page.Title
                    classname="w-3/5 lg:w-full"
                    title={title}
                />
                <div className="w-full flex flex-row justify-end items-center">
                    {edit ? (
                        <>
                            <Button
                                classname="mr-2"
                                type="button"
                                size="sm"
                                color="primary"
                                leftIcon={<CheckIcon className={`h-4 w-4 stroke-2 self-center`} />}
                                onClick={onSaveButtonClicked}
                            >
                                {t("buttons.save")}
                            </Button>
                            <Page.DiscardChangesButton
                                text={t("buttons.discardchanges")}
                                onDiscardChanges={onDiscardChanges}
                            />
                        </>
                    ) : (
                        <Page.EditEntityButton
                            text={t("buttons.edit")}
                            onEditEntity={onEditButtonClicked}
                        />
                    )}
                </div>
            </div>
        </Modal.Header>
    )
}
import { FileCard } from "@whyuz/components";
import { SuccessCaseContentItem, useServerFiles } from "@whyuz/services";
import { useMemo } from "react";

export interface SuccessCaseQuestionCardFilesProps {
  question: SuccessCaseContentItem;
}

export const SuccessCaseQuestionCardFiles = ({ question }: SuccessCaseQuestionCardFilesProps) => {
  const asset = useMemo(() => {
    return question.assets && question.assets[0] ? question.assets[0] : undefined;
  }, [question]);

  const serverFiles = useServerFiles();

  return (
    <div className="w-fit p-5">
      {asset && asset.accessToken && (
        <FileCard
          key={question.title}
          url={serverFiles.getFileURIByAssetToken(asset.accessToken)}
          fileName={asset.originalFilename as string}
          fileSizeBytes={asset.fileSizeBytes as number}
          fileExtension={asset.originalExtension as string}
        />
      )}
    </div>
  );
};

import { Button, Page } from "@whyuz/components";

import { ApprovalFlowExecutionStatus } from "@whyuz/services";
import { Tabs } from "flowbite-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApprovalFlowExecutionHistory } from "./ApprovalFlowExecutionHistory/ApprovalFlowExecutionHistory.tsx";
import { AddOrEditApprovalFlowExecutionSteps, useApprovalFlowExecutionBuilderContext } from "./index.ts";
import { HandThumbUpIcon } from "@heroicons/react/24/outline";
import { ApprovalFlowExecutionBadge } from "../ApprovalFlows";

interface ApprovalFlowExecutionCoreProps {
  showDeleteButton?: boolean;
  onDelete?: () => void;
}
export const ApprovalFlowExecutionCore = ({ showDeleteButton = false, onDelete }: ApprovalFlowExecutionCoreProps) => {
  const { t } = useTranslation();
  const { t: tApproval } = useTranslation("approval");

  const { approvalFlowExecution, resendEmailsAppFlow } = useApprovalFlowExecutionBuilderContext();

  const [activeTab, setActiveTab] = useState<number>(
    approvalFlowExecution?.status !== ApprovalFlowExecutionStatus.NotLaunched ? 2 : 0,
  );

  return (
    <>
      <div className="w-full flex justify-between items-center">
        <ApprovalFlowExecutionBadge status={approvalFlowExecution?.status as ApprovalFlowExecutionStatus} />
        {showDeleteButton && onDelete && approvalFlowExecution?.status === ApprovalFlowExecutionStatus.NotLaunched && (
          <Page.DeleteEntityButton text={t("buttons.delete")} onDeleteEntity={onDelete} />
        )}
        {approvalFlowExecution?.status === ApprovalFlowExecutionStatus.Launched && (
          <Button
            leftIcon={<HandThumbUpIcon className="h-4 w-4 stroke-2 self-center" />}
            type="button"
            size="sm"
            breakpointSM={false}
            onPointerDown={resendEmailsAppFlow}>
            {tApproval("resendappflow")}
          </Button>
        )}
      </div>
      <Tabs style="underline" onActiveTabChange={setActiveTab}>
        <Tabs.Item active={activeTab === 0} title={tApproval("approvers")}>
          <AddOrEditApprovalFlowExecutionSteps />
        </Tabs.Item>
        <Tabs.Item
          active={activeTab === 1}
          disabled={approvalFlowExecution?.status === ApprovalFlowExecutionStatus.NotLaunched}
          title={tApproval("history")}>
          <ApprovalFlowExecutionHistory />
        </Tabs.Item>
      </Tabs>
    </>
  );
};

import { Collaboration, CollaborationStatus, CollaborationType } from "@whyuz/services";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CollaborationCardSuccessCaseButton } from "./CollaborationCardSuccessCaseButton";
import { CollaborationStatusBadge } from "./CollaborationStatusBadge.tsx";

export interface CollaborationCardBodyProps {
  collaboration: Collaboration;
}

export const CollaborationCardBody = ({ collaboration }: CollaborationCardBodyProps) => {
  const { t: tCollaboration } = useTranslation("collaboration");

  const textTranslationKey = useMemo(() => {
    switch (collaboration.entityType) {
      case CollaborationType.SuccessCase:
        return "collaborationcard.invitationtext.successcase";
      default:
        return "collaborationcard.invitationtext.default";
    }
  }, [collaboration.entityType]);

  const collaborationButton = useMemo(() => {
    switch (collaboration.entityType) {
      case CollaborationType.SuccessCase:
        return <CollaborationCardSuccessCaseButton collaboration={collaboration} />;
      default:
        return undefined;
    }
  }, [collaboration]);

  return (
    <div className="flex flex-col justify-center space-y-2 p-5 h-60 bg-white dark:bg-gray-700">
      <span className="leading-tight text-xl sm:text-2xl font-bold text-gray-900 dark:text-gray-200 select-none">
        <div>
          <CollaborationStatusBadge collaborationStatus={collaboration.status as CollaborationStatus} />
        </div>
        <Trans
          i18nKey={textTranslationKey}
          t={tCollaboration}
          values={{
            inviterName: collaboration.inviterName,
            companyName: collaboration.tenant?.name,
          }}
        />
      </span>
      <p className="text-base font-normal text-gray-600 dark:text-gray-400 pb-2 select-none">{collaboration.title}</p>
      <div className="flex items-center justify-center">{collaborationButton}</div>
    </div>
  );
};

import { ApprovalFlowExecutionStatus } from "@whyuz/services";
import { FlowbiteColors } from "flowbite-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface ApprovalFlowStatusEntity {
  id: ApprovalFlowExecutionStatus;
  name: string;
  color: keyof FlowbiteColors;
}

export const useApprovalFlowStatusTranslations = (): Record<ApprovalFlowExecutionStatus, ApprovalFlowStatusEntity> => {
  const { t: tApproval } = useTranslation("approval");
  return useMemo(() => {
    return {
      [ApprovalFlowExecutionStatus.Approved]: {
        id: ApprovalFlowExecutionStatus.Approved,
        name: tApproval("approvalflowstatus.APPROVED"),
        color: "green",
      },
      [ApprovalFlowExecutionStatus.Cancelled]: {
        id: ApprovalFlowExecutionStatus.Cancelled,
        name: tApproval("approvalflowstatus.CANCELLED"),
        color: "red",
      },
      [ApprovalFlowExecutionStatus.Launched]: {
        id: ApprovalFlowExecutionStatus.Launched,
        name: tApproval("approvalflowstatus.LAUNCHED"),
        color: "blue",
      },
      [ApprovalFlowExecutionStatus.NotLaunched]: {
        id: ApprovalFlowExecutionStatus.NotLaunched,
        name: tApproval("approvalflowstatus.NOT_LAUNCHED"),
        color: "yellow",
      },
      [ApprovalFlowExecutionStatus.Rejected]: {
        id: ApprovalFlowExecutionStatus.Rejected,
        name: tApproval("approvalflowstatus.REJECTED"),
        color: "purple",
      },
    };
  }, [tApproval]);
};

import { AdvancedTable, Card, Text, useAdvancedTable } from "@whyuz/components";
import { ApprovalFlowTemplateStep, ApprovalFlowTemplateUser, GQLSort, useUser } from "@whyuz/services";
import { Label } from "flowbite-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  filterAndSortAppFlowUsersToShow,
  useApprovalFlowTemplateUsersTableConfiguration,
} from "../../ApprovalFlows/hooks/useApprovalFlowTemplateUsersTableConfiguration.tsx";

interface ApprovalFlowTemplateStepCardModalProps {
  approvalFlowTemplateStep: ApprovalFlowTemplateStep;
}
export const ApprovalFlowTemplateStepCardModal = ({
  approvalFlowTemplateStep,
}: ApprovalFlowTemplateStepCardModalProps) => {
  const { t: tApproval } = useTranslation("approval");
  const userCtx = useUser();

  const approvalFlowUserTableConfiguration = useApprovalFlowTemplateUsersTableConfiguration<ApprovalFlowTemplateUser>();
  const approvalFlowUserTableState = useAdvancedTable<ApprovalFlowTemplateUser, ApprovalFlowTemplateUser>({
    tableConfiguration: approvalFlowUserTableConfiguration,
  });

  const approvalFlowUserToShow = useMemo(
    () =>
      filterAndSortAppFlowUsersToShow<ApprovalFlowTemplateUser>(
        approvalFlowTemplateStep.users as ApprovalFlowTemplateUser[],
        approvalFlowUserTableState.filters,
        userCtx.isUserSuperAdmin,
        approvalFlowUserTableState.orderBy as GQLSort<ApprovalFlowTemplateUser>,
      ),
    [
      approvalFlowTemplateStep.users,
      approvalFlowUserTableState.filters,
      approvalFlowUserTableState.orderBy,
      userCtx.isUserSuperAdmin,
    ],
  );

  return (
    <Card className="cursor-auto">
      <div className="flex flex-col w-full">
        <div className="flex flex-col md:flex-row justify-between w-full ">
          <Text className="text-gray-500 dark:text-gray-400">
            {tApproval("numbers.approvalstepWithCount", {
              count: (approvalFlowTemplateStep.stepNumber as number) + 1,
              ordinal: true,
            })}
          </Text>
          <Label className="text-gray-500 dark:text-gray-400">
            {approvalFlowTemplateStep.requiredAll ? tApproval("allusersrequired") : tApproval("atleastoneuserrequired")}
          </Label>
        </div>
        <div className="w-full flex flex-col gap-4 mt-4">
          <AdvancedTable tableState={approvalFlowUserTableState} entitiesToShow={approvalFlowUserToShow} />
        </div>
      </div>
    </Card>
  );
};

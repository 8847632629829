import {
  AddMailTemplateLanguageMutationVariables,
  DeleteMailTemplateLanguageMutationVariables,
  MailTemplateLanguage,
  MailTemplateLanguagePage,
  MailTemplateLanguageQueryVariables,
  MailTemplateLanguagesQueryVariables,
  UpdateMailTemplateLanguageMutationVariables,
} from "../../codegen/graphql.ts";
import { MailTemplateLanguageGQL } from "../../queries";
import { GQLQueryContext } from "../../types";
import { useGQLLazyQuery, useGQLMutation, useGQLQuery } from "../useGQLQuery.ts";

export const useAddMailTemplateLanguageMutation = (
  mutationContext?: GQLQueryContext<MailTemplateLanguage, AddMailTemplateLanguageMutationVariables>,
) =>
  useGQLMutation<MailTemplateLanguage, AddMailTemplateLanguageMutationVariables>(
    MailTemplateLanguageGQL.mutation.addMailTemplateLanguage,
    mutationContext,
  );

export const useUpdateMailTemplateLanguageMutation = (
  mutationContext?: GQLQueryContext<MailTemplateLanguage, UpdateMailTemplateLanguageMutationVariables>,
) =>
  useGQLMutation<MailTemplateLanguage, UpdateMailTemplateLanguageMutationVariables>(
    MailTemplateLanguageGQL.mutation.updateMailTemplateLanguage,
    mutationContext,
  );

export const useDeleteMailTemplateLanguageMutation = (
  mutationContext?: GQLQueryContext<MailTemplateLanguage, DeleteMailTemplateLanguageMutationVariables>,
) =>
  useGQLMutation<MailTemplateLanguage, DeleteMailTemplateLanguageMutationVariables>(
    MailTemplateLanguageGQL.mutation.deleteMailTemplateLanguage,
    mutationContext,
  );

export const useMailTemplateLanguageLazyQuery = (
  queryContext?: GQLQueryContext<MailTemplateLanguage, MailTemplateLanguageQueryVariables>,
) =>
  useGQLLazyQuery<MailTemplateLanguage, MailTemplateLanguageQueryVariables>(
    MailTemplateLanguageGQL.query.mailTemplateLanguage,
    queryContext,
  );

export const useMailTemplateLanguageQuery = (
  queryContext?: GQLQueryContext<MailTemplateLanguage, MailTemplateLanguageQueryVariables>,
) =>
  useGQLQuery<MailTemplateLanguage, MailTemplateLanguageQueryVariables>(
    MailTemplateLanguageGQL.query.mailTemplateLanguage,
    queryContext,
  );

export const useMailTemplateLanguagesLazyQuery = (
  queryContext?: GQLQueryContext<MailTemplateLanguagePage, MailTemplateLanguagesQueryVariables>,
) =>
  useGQLLazyQuery<MailTemplateLanguagePage, MailTemplateLanguagesQueryVariables>(
    MailTemplateLanguageGQL.query.mailTemplateLanguages,
    queryContext,
  );

export const useMailTemplateLanguagesQuery = (
  queryContext?: GQLQueryContext<MailTemplateLanguagePage, MailTemplateLanguagesQueryVariables>,
) =>
  useGQLQuery<MailTemplateLanguagePage, MailTemplateLanguagesQueryVariables>(
    MailTemplateLanguageGQL.query.mailTemplateLanguages,
    queryContext,
  );

import { Banner, Card, useContentTypeIcon } from "@whyuz/components";
import { ContentType, SuccessCaseContentItem } from "@whyuz/services";
import { useTranslation } from "react-i18next";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { SuccessCaseContentItemLimitBadge, SuccessCaseContentItemTypeBadge } from "../..";
import { SuccessCaseQuestionItemType } from "./SuccessCaseQuestionItemType.tsx";

export interface CardSuccessCaseContentItemProps {
  value: SuccessCaseContentItem;
  questionNumber: number;
}
export const SuccessCaseContentItemCard = ({ value, questionNumber }: CardSuccessCaseContentItemProps) => {
  const { t: tSuccessCase } = useTranslation("successCaseTemplate");
  const contentTypeIcon = useContentTypeIcon("h-8 w-8 text-primary-700");

  return (
    <Card className="w-full mb-2 cursor-auto" id={value.id as string}>
      <div className="flex flex-col w-full space-y-2">
        <Card.Header
          title={tSuccessCase("successcasetemplatecontentitem.numbers.itemWithCount", {
            count: questionNumber,
          })}
          titleStyles="text-primary-700 dark:text-primary-700 font-semibold"
          icon={contentTypeIcon[value.contentType as ContentType]}
          detail={
            <>
              <SuccessCaseContentItemTypeBadge contentType={value.contentType as ContentType} />
              {value.contentMaxSize && (
                <SuccessCaseContentItemLimitBadge
                  contentType={value.contentType as ContentType}
                  limit={value.contentMaxSize ?? undefined}
                />
              )}
            </>
          }></Card.Header>
        <div className="w-full flex flex-col space-y-2">
          <h1 className="font-bold pl-2 dark:text-white">{value.title}</h1>
          {value.htmlInstructions && (
            <Banner>
              <>
                <div className="font-semibold text-sm flex flex-row space-x-2">
                  <DocumentTextIcon className="h-4 w-4 text-primary-600" />
                  <div>{tSuccessCase("successcasetemplatecontentitem.instructions")}</div>
                </div>
                <div className="text-xs">{value.htmlInstructions}</div>
              </>
            </Banner>
          )}
          <SuccessCaseQuestionItemType question={value} />
        </div>
      </div>
    </Card>
  );
};

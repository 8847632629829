import { ApprovalFlowStepExecutionStatus } from "@whyuz/services";
import { Badge } from "flowbite-react";
import { useApprovalFlowStepAndUserStatusTranslations } from "../hooks/useApprovalFlowStepAndUserStatus.tsx";

interface ApprovalFlowExecutionStepAndUserStatusBadgeProps {
  status?: ApprovalFlowStepExecutionStatus;
}
export const ApprovalFlowExecutionStepAndUserStatusBadge = ({
  status,
}: ApprovalFlowExecutionStepAndUserStatusBadgeProps) => {
  const approvalFlowStepsAndUserStatusTranslations = useApprovalFlowStepAndUserStatusTranslations();

  return (
    <Badge
      className="flex w-fit"
      color={approvalFlowStepsAndUserStatusTranslations[status as ApprovalFlowStepExecutionStatus].color}>
      {approvalFlowStepsAndUserStatusTranslations[status as ApprovalFlowStepExecutionStatus].name}
    </Badge>
  );
};

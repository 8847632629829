import { SuccessCase, SuccessCaseContentItem } from "@whyuz/services";
import { useTranslation } from "react-i18next";
import { Banner } from "@whyuz/components";
import { SuccessCaseContentItemCard } from "@whyuz/app-components";
export interface QuestionsProps {
  successCase: SuccessCase;
}

export const CollaborationSuccessCaseQuestions = ({ successCase }: QuestionsProps) => {
  const { t } = useTranslation();
  const { t: tSuccessCase } = useTranslation("successCaseTemplate");

  return (
    <div className="flex flex-col space-y-2">
      <Banner>
        <Banner.Title title={tSuccessCase("description")} />
        <div className="text-xs">{successCase.customerDescription}</div>
      </Banner>
      {successCase.contentItems && successCase.contentItems.length > 0 ? (
        successCase.contentItems.map((contentItem, i) => (
          <SuccessCaseContentItemCard key={i} value={contentItem as SuccessCaseContentItem} questionNumber={i + 1} />
        ))
      ) : (
        <div className="italic w-full text-start text-gray-500 dark:text-gray-400">{t("errors.itemsnotfound")}</div>
      )}
    </div>
  );
};

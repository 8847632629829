import { PlusIcon } from "@heroicons/react/24/outline";
import { Button } from "@whyuz/components";
import { ApprovalFlowExecutionStep, ApprovalFlowExecutionUser, ApprovalFlowType } from "@whyuz/services";
import { Reorder } from "framer-motion";
import { useState } from "react";
import { ApprovalFlowStep } from "../ApprovalFlows/components/ApprovalFlowStep/ApprovalFlowStep.tsx";
import { useApprovalFlowExecutionBuilderContext } from "./ApprovalFlowExecutionContext.tsx";
import { ApprovalFlowExecutionStepCard } from "./ApprovalFlowStepCard.tsx";
import { useTranslation } from "react-i18next";

export const AddOrEditApprovalFlowExecutionSteps = () => {
  const { t: tApproval } = useTranslation("approval");

  const {
    approvalFlowExecution,
    approvalFlowExecutionSteps,
    addStep,
    readOnly,
    consolidateSteps,
    addUser,
    deleteStep,
    deleteUser,
    updateStep,
    error,
  } = useApprovalFlowExecutionBuilderContext();
  const [data, setData] = useState<ApprovalFlowExecutionStep[]>(approvalFlowExecutionSteps || []);
  const reorder = (steps: ApprovalFlowExecutionStep[]) => {
    setData(steps);
  };

  const finishDrag = (step: ApprovalFlowExecutionStep) => {
    consolidateSteps(step, data.indexOf(step));
  };

  return (
    <div className="flex flex-col justify-center items-center w-full gap-4">
      {!readOnly && approvalFlowExecutionSteps && (
        <>
          <Reorder.Group values={approvalFlowExecutionSteps} onReorder={reorder} className="flex flex-col gap-2 w-full">
            {approvalFlowExecutionSteps?.map((step, i) => (
              <ApprovalFlowStep<ApprovalFlowExecutionStep, ApprovalFlowExecutionUser>
                key={step.id as string}
                consolidateSteps={finishDrag}
                step={step}
                deleteStep={deleteStep}
                deleteUser={deleteUser}
                i={i}
                addUser={addUser}
                updateStep={updateStep}
                errors={error}
                steps={approvalFlowExecutionSteps.length}
                approvalFlowType={approvalFlowExecution?.type as ApprovalFlowType}
              />
            ))}
          </Reorder.Group>
          <Button onPointerDown={addStep} leftIcon={<PlusIcon className="h-4 w-4 stroke-2 self-center" />}>
            {tApproval("createstep")}
          </Button>
        </>
      )}
      {readOnly && approvalFlowExecutionSteps && (
        <div>
          {approvalFlowExecutionSteps?.map((step) => (
            <ApprovalFlowExecutionStepCard key={step.id as string} approvalFlowExecutionStep={step} />
          ))}
        </div>
      )}
    </div>
  );
};

import { Badge } from "flowbite-react";
import { useContentTypeIcon, useSuccessCaseStatusTranslations } from "@whyuz/components";
import { ContentType } from "@whyuz/services";

interface SuccessCaseContentItemTypeBadgeProps {
  contentType: ContentType;
}
export const SuccessCaseContentItemTypeBadge = ({ contentType }: SuccessCaseContentItemTypeBadgeProps) => {
  const contentTypeTranslations = useSuccessCaseStatusTranslations();
  const contentTypeIcons = useContentTypeIcon("text-primary-800 dark:text-primary-900");

  return (
    <Badge className="flex w-fit" color="blue">
      <div className="flex flex-row space-x-2">
        <div> {contentTypeIcons[contentType]}</div>
        <div className="whitespace-nowrap"> {contentTypeTranslations[contentType].name}</div>
      </div>
    </Badge>
  );
};

import { AdvancedTable, Card, Page, RadioButton, RadioButtonGroup, useAdvancedTable } from "@whyuz/components";
import {
  ApprovalFlowExecutionStep,
  ApprovalFlowExecutionUser,
  ApprovalFlowTemplateStep,
  ApprovalFlowTemplateUser,
  ApprovalFlowType,
  GQLError,
  GQLSort,
  useUser,
} from "@whyuz/services";
import { Reorder } from "framer-motion";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "flowbite-react";
import {
  APP_FLOW_USERS_TABLE_COLUMNS,
  filterAndSortAppFlowUsersToShow,
  useApprovalFlowTemplateUsersTableConfiguration,
} from "../../hooks/useApprovalFlowTemplateUsersTableConfiguration.tsx";
import { ApprovalFlowUserComponent } from "./ApprovalFlowUserComponent.tsx";

export interface AddUserProps {
  name?: string | null;
  email?: string | null;
  userId?: string | null;
  stepId: string;
}
export interface ApprovalFlowStepProps<
  T extends ApprovalFlowTemplateStep | ApprovalFlowExecutionStep,
  U extends ApprovalFlowExecutionUser | ApprovalFlowTemplateUser,
> {
  consolidateSteps: (step: T) => void;
  step: T;
  deleteUser: (user: U, stepId: string) => void;
  i: number;
  addUser: (user: AddUserProps) => void;
  updateStep: (step: T) => void;
  deleteStep: (id: string) => void;
  errors: GQLError | undefined;
  steps: number;
  approvalFlowType: ApprovalFlowType;
}

export const ApprovalFlowStep = <
  T extends ApprovalFlowTemplateStep | ApprovalFlowExecutionStep,
  U extends ApprovalFlowExecutionUser | ApprovalFlowTemplateUser,
>({
  i,
  step,
  updateStep,
  deleteStep,
  consolidateSteps,
  addUser,
  deleteUser,
  errors,
  steps,
  approvalFlowType,
}: ApprovalFlowStepProps<T, U>) => {
  const { t: tApproval } = useTranslation("approval");
  const { t } = useTranslation();
  const userCtx = useUser();

  const approvalFlowUserTableConfiguration = useApprovalFlowTemplateUsersTableConfiguration<U>();
  const approvalFlowUserTableState = useAdvancedTable<U, U>({
    tableConfiguration: approvalFlowUserTableConfiguration,
    hideColumnsKeys: [APP_FLOW_USERS_TABLE_COLUMNS.STATUS],
  });

  const approvalFlowUserToShow = useMemo(
    () =>
      filterAndSortAppFlowUsersToShow<U>(
        step.users as U[],
        approvalFlowUserTableState.filters,
        userCtx.isUserSuperAdmin,
        approvalFlowUserTableState.orderBy as GQLSort<ApprovalFlowExecutionUser | ApprovalFlowTemplateUser>,
      ),
    [step.users, approvalFlowUserTableState.filters, approvalFlowUserTableState.orderBy, userCtx.isUserSuperAdmin],
  );

  return (
    <Reorder.Item
      onDragEnd={() => {
        consolidateSteps(step);
      }}
      transition={{ duration: 0 }}
      key={step?.id as string}
      value={step}>
      <Card>
        <div className="flex flex-col w-full">
          <Card.Header
            isDraggable
            title={tApproval("numbers.approvalstepWithCount", {
              count: i + 1,
              ordinal: true,
            })}>
            {steps > 1 && <Page.DeleteEntityMinimalisticButton onDeleteEntity={() => deleteStep(step?.id as string)} />}
          </Card.Header>
          <div id="appFlowCardBody" className="w-full flex flex-col gap-4">
            <Page.FormGrid columns={3} columnsBreakpointSM={true}>
              <ApprovalFlowUserComponent
                stepId={step.id as string}
                approvalFlowType={approvalFlowType}
                addUser={addUser}
                errors={errors}
              />
              <RadioButtonGroup
                onChange={(value) => {
                  updateStep({ ...step, requiredAll: value === "true" });
                }}
                label={tApproval("approvewhen")}
                name={`${step.id}-type`}
                type="flex-col"
                initialValue={String(step.requiredAll)}>
                <RadioButton value={"false"}>{tApproval("atleastone")}</RadioButton>
                <RadioButton value={"true"}>{tApproval("allaccepted")}</RadioButton>
              </RadioButtonGroup>
            </Page.FormGrid>

            <div id="AppFlowUsersTable">
              <Label className="text-sm font-semibold text-gray-900 select-none">{t("word.users")}</Label>
              <AdvancedTable
                tableState={approvalFlowUserTableState}
                entitiesToShow={approvalFlowUserToShow}
                onDeleteEntity={(user) => deleteUser(user, step.id as string)}
              />
            </div>
          </div>
        </div>
      </Card>
    </Reorder.Item>
  );
};

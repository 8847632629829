import { graphql } from "../../codegen/gql";

const addMailTemplateLanguage = graphql(`
  mutation addMailTemplateLanguage($mailTemplateLanguage: MailTemplateLanguageInput!) {
    addMailTemplateLanguage(mailTemplateLanguage: $mailTemplateLanguage) {
      id
      language {
        id
        name
        flagCode
        es
        it
        fr
        de
        pt
      }
      subject
      body
      mailTemplate {
        id
        type
      }
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
    }
  }
`);

const updateMailTemplateLanguage = graphql(`
  mutation updateMailTemplateLanguage($id: UUID!, $mailTemplateLanguage: MailTemplateLanguageInput!) {
    updateMailTemplateLanguage(id: $id, mailTemplateLanguage: $mailTemplateLanguage) {
      id
      language {
        id
        name
        flagCode
        es
        it
        fr
        de
        pt
      }
      subject
      body
      mailTemplate {
        id
        type
        name
      }
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
    }
  }
`);

const deleteMailTemplateLanguage = graphql(`
  mutation deleteMailTemplateLanguage($id: UUID!) {
    deleteMailTemplateLanguage(id: $id) {
      id
      language {
        id
        name
        flagCode
        es
        it
        fr
        de
        pt
      }
      subject
      body
      mailTemplate {
        id
        type
      }
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
    }
  }
`);

const mailTemplateLanguages = graphql(`
  query mailTemplateLanguages(
    $filterExpression: FilterExpression
    $page: Int
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    mailTemplateLanguages(
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        subject
        body
        language {
          id
          name
          flagCode
          es
          it
          fr
          de
          pt
        }
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        mailTemplate {
          id
          name
          type
          mailTemplateLanguages {
            language {
              id
              name
              flagCode
              es
              it
              fr
              de
              pt
            }
          }
        }
      }
    }
  }
`);

const mailTemplateLanguage = graphql(`
  query mailTemplateLanguage($id: UUID!) {
    mailTemplateLanguage(id: $id) {
      id
      language {
        id
        name
        flagCode
        es
        it
        fr
        de
        pt
      }
      subject
      body
      mailTemplate {
        id
        type
        name
      }
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
    }
  }
`);

export const MailTemplateLanguageGQL = {
  query: { mailTemplateLanguage, mailTemplateLanguages },
  mutation: { addMailTemplateLanguage, updateMailTemplateLanguage, deleteMailTemplateLanguage },
};

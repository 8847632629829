import { AssignedReward, BenefitRewardType } from "@whyuz/services";

export const isAValidReward = (reward?: AssignedReward) => {
  if (!reward?.type) {
    return false;
  } else {
    return !(
      (reward.type === BenefitRewardType.CustomReward ||
        reward.type === BenefitRewardType.CustomBenefit ||
        reward.type === BenefitRewardType.Discount) &&
      !reward.name
    );
  }
};

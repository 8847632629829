import {
  AddMailAccountMutationVariables,
  DeleteMailAccountMutationVariables,
  DeleteMailAccountsMutationVariables,
  MailAccount,
  MailAccountPage,
  MailAccountQueryVariables,
  MailAccountsQueryVariables,
  UpdateMailAccountMutationVariables,
} from "../../codegen/graphql.ts";
import { MailAccountGQL } from "../../queries/tenant/mailAccount.tsx";
import { GQLQueryContext } from "../../types";
import { useGQLLazyQuery, useGQLMutation, useGQLQuery } from "../useGQLQuery.ts";

export const useAddMailAccountMutation = (
  mutationContext?: GQLQueryContext<MailAccount, AddMailAccountMutationVariables>,
) =>
  useGQLMutation<MailAccount, AddMailAccountMutationVariables>(MailAccountGQL.mutation.addMailAccount, mutationContext);

export const useUpdateMailAccountMutation = (
  mutationContext?: GQLQueryContext<MailAccount, UpdateMailAccountMutationVariables>,
) =>
  useGQLMutation<MailAccount, UpdateMailAccountMutationVariables>(
    MailAccountGQL.mutation.updateMailAccount,
    mutationContext,
  );

export const useDeleteMailAccountMutation = (
  mutationContext?: GQLQueryContext<MailAccount, DeleteMailAccountMutationVariables>,
) =>
  useGQLMutation<MailAccount, DeleteMailAccountMutationVariables>(
    MailAccountGQL.mutation.deleteMailAccount,
    mutationContext,
  );

export const useDeleteMailAccountsMutation = (
  mutationContext?: GQLQueryContext<MailAccount, DeleteMailAccountsMutationVariables>,
) =>
  useGQLMutation<MailAccount, DeleteMailAccountsMutationVariables>(
    MailAccountGQL.mutation.deleteMailAccounts,
    mutationContext,
  );

export const useMailAccountsLazyQuery = (queryContext?: GQLQueryContext<MailAccountPage, MailAccountsQueryVariables>) =>
  useGQLLazyQuery<MailAccountPage, MailAccountsQueryVariables>(MailAccountGQL.query.mailAccounts, queryContext);

export const useMailAccountsQuery = (queryContext?: GQLQueryContext<MailAccountPage, MailAccountsQueryVariables>) =>
  useGQLQuery<MailAccountPage, MailAccountsQueryVariables>(MailAccountGQL.query.mailAccounts, queryContext);

export const useMailAccountLazyQuery = (queryContext?: GQLQueryContext<MailAccount, MailAccountQueryVariables>) =>
  useGQLLazyQuery<MailAccount, MailAccountQueryVariables>(MailAccountGQL.query.mailAccount, queryContext);

export const useMailAccountQuery = (queryContext?: GQLQueryContext<MailAccount, MailAccountQueryVariables>) =>
  useGQLQuery<MailAccount, MailAccountQueryVariables>(MailAccountGQL.query.mailAccount, queryContext);

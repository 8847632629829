import { useContext } from "react";
import { LanguagesContext, LanguagesContextType } from "../context/LanguageContext";

export const useLanguages = () => {
  const context = useContext(LanguagesContext) as LanguagesContextType;

  if (!context) {
    throw new Error("useLanguages must be used within a LanguagesContextProvider");
  }

  return context;
};

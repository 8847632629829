import { Badge } from "flowbite-react";
import { useContentSizeUnitsOfMeasureLimit } from "@whyuz/components";
import { ContentType } from "@whyuz/services";

interface SuccessCaseContentItemTypeBadgeProps {
  contentType: ContentType;
  limit?: number;
}
export const SuccessCaseContentItemLimitBadge = ({ contentType, limit }: SuccessCaseContentItemTypeBadgeProps) => {
  const contentSizeUnitsOfMeasureLimitTranslations = useContentSizeUnitsOfMeasureLimit(limit);
  return (
    <Badge className="flex w-fit" color="blue">
      <div className="flex flex-row space-x-2">
        {limit && limit > 0 ? (
          <div className="whitespace-nowrap">{contentSizeUnitsOfMeasureLimitTranslations[contentType].name}</div>
        ) : (
          <div className="whitespace-nowrap">{contentSizeUnitsOfMeasureLimitTranslations[ContentType.Free].name}</div>
        )}
      </div>
    </Badge>
  );
};

import { graphql } from "../../codegen/gql";

const addMailAccount = graphql(`
  mutation addMailAccount($mailAccount: MailAccountInput!) {
    addMailAccount(mailAccount: $mailAccount) {
      id
      name
      user
      fromName
      mailServer
      host
      port
      mailProtocol
      enableTLS
      SSLProtocol
      enableAuthentication
      enabled
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
    }
  }
`);

const updateMailAccount = graphql(`
  mutation updateMailAccount($id: UUID!, $mailAccount: MailAccountInput!) {
    updateMailAccount(id: $id, mailAccount: $mailAccount) {
      id
      name
      user
      fromName
      mailServer
      host
      port
      mailProtocol
      enableTLS
      SSLProtocol
      enableAuthentication
      enabled
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
    }
  }
`);

const deleteMailAccount = graphql(`
  mutation deleteMailAccount($id: UUID!) {
    deleteMailAccount(id: $id) {
      id
      name
      user
      fromName
      mailServer
      host
      port
      mailProtocol
      enableTLS
      SSLProtocol
      enableAuthentication
      enabled
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
    }
  }
`);

const deleteMailAccounts = graphql(`
  mutation deleteMailAccounts($ids: [UUID]!) {
    deleteMailAccounts(ids: $ids) {
      id
      name
      user
      fromName
      mailServer
      host
      port
      mailProtocol
      enableTLS
      SSLProtocol
      enableAuthentication
      enabled
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
    }
  }
`);

const mailAccounts = graphql(`
  query mailAccounts(
    $filterExpression: FilterExpression
    $page: Int
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    mailAccounts(
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        name
        user
        fromName
        mailServer
        host
        port
        mailProtocol
        enableTLS
        SSLProtocol
        enableAuthentication
        enabled
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
      }
    }
  }
`);

const mailAccount = graphql(`
  query mailAccount($id: UUID!) {
    mailAccount(id: $id) {
      id
      name
      user
      fromName
      mailServer
      host
      port
      mailProtocol
      enableTLS
      SSLProtocol
      enableAuthentication
      enabled
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
    }
  }
`);

export const MailAccountGQL = {
  query: { mailAccounts, mailAccount },
  mutation: { addMailAccount, updateMailAccount, deleteMailAccount, deleteMailAccounts },
};

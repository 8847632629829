import { ApprovalFlowStepExecutionStatus } from "@whyuz/services";
import { FlowbiteColors } from "flowbite-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface UseApprovalFlowStepAndUserStatusEntity {
  id: ApprovalFlowStepExecutionStatus;
  name: string;
  color: keyof FlowbiteColors;
}

export const useApprovalFlowStepAndUserStatusTranslations = (): Record<
  ApprovalFlowStepExecutionStatus,
  UseApprovalFlowStepAndUserStatusEntity
> => {
  const { t: tApproval } = useTranslation("approval");
  return useMemo(() => {
    return {
      [ApprovalFlowStepExecutionStatus.NotRequested]: {
        id: ApprovalFlowStepExecutionStatus.NotRequested,
        name: tApproval("statususerinformation.NOT_REQUESTED"),
        color: "yellow",
      },
      [ApprovalFlowStepExecutionStatus.Pending]: {
        id: ApprovalFlowStepExecutionStatus.Pending,
        name: tApproval("statususerinformation.PENDING"),
        color: "blue",
      },
      [ApprovalFlowStepExecutionStatus.Approved]: {
        id: ApprovalFlowStepExecutionStatus.Approved,
        name: tApproval("statususerinformation.APPROVED"),
        color: "green",
      },
      [ApprovalFlowStepExecutionStatus.Rejected]: {
        id: ApprovalFlowStepExecutionStatus.Rejected,
        name: tApproval("statususerinformation.REJECTED"),
        color: "red",
      },
      [ApprovalFlowStepExecutionStatus.NotNeeded]: {
        id: ApprovalFlowStepExecutionStatus.NotNeeded,
        name: tApproval("statususerinformation.NOT_NEEDED"),
        color: "purple",
      },
      [ApprovalFlowStepExecutionStatus.ApprovalCancelled]: {
        id: ApprovalFlowStepExecutionStatus.ApprovalCancelled,
        name: tApproval("statususerinformation.APPROVAL_CANCELLED"),
        color: "red",
      },
    };
  }, [tApproval]);
};

import { ComboboxAutocomplete, ComboboxAutocompleteProps, ComboboxItemDictionary } from "@whyuz/components";
import {
  FilterExpression,
  FilterExpressionOperator,
  FilterOperator,
  GQLError,
  MailAccount,
  MailAccountPage,
  SortDirection,
  useMailAccountsLazyQuery,
} from "@whyuz/services";
import { getColSpan, isEmpty, notifyError } from "@whyuz/utils";
import { HelperText } from "flowbite-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export const MailAccountCombobox = ({
  columns,
  columnsBreakpointSM,
  ...props
}: Omit<ComboboxAutocompleteProps<MailAccount>, "items">) => {
  const { t: tMailAccount } = useTranslation("mailAccount");
  const [userError, setUserError] = useState<GQLError>();
  const [mailAccountItems, setMailAccountItems] = useState<ComboboxItemDictionary<MailAccount>>({});
  const [mailAccountsQuery, { isLoading: isLoadingMailAccounts }] = useMailAccountsLazyQuery();

  const onMailAccountSearchTextChange = useCallback(
    (searchText: string | null) => {
      const filterExpression: FilterExpression | undefined = isEmpty(searchText)
        ? undefined
        : ({
            operator: FilterExpressionOperator.Or,
            fields: [
              { field: "id", operator: FilterOperator.Like, value: "%" + searchText + "%" },
              { field: "fromName", operator: FilterOperator.Like, value: "%" + (searchText ?? "") + "%" },
              { field: "name", operator: FilterOperator.Like, value: "%" + (searchText ?? "") + "%" },
              { field: "user", operator: FilterOperator.Like, value: "%" + (searchText ?? "") + "%" },
            ],
          } as FilterExpression);

      mailAccountsQuery({
        variables: {
          filterExpression,
          pageSize: 20,
          sortField: "user",
          sortDirection: SortDirection.Asc,
        },
      })
        .then((page: MailAccountPage) => {
          const items = page?.content
            ? page.content.reduce(
                (prev, mailAccount) =>
                  mailAccount
                    ? ({
                        ...prev,
                        [mailAccount.id]: {
                          id: mailAccount.id as string,
                          name: mailAccount.user,
                          entity: mailAccount,
                        },
                      } as ComboboxItemDictionary<MailAccount>)
                    : prev,
                {} as ComboboxItemDictionary<MailAccount>,
              )
            : {};
          setMailAccountItems(items);
          setUserError(undefined);
        })
        .catch((usersError: GQLError) => {
          setUserError(() => usersError);
          notifyError(tMailAccount("errors.retrievingmailaccounts"));
        });
    },
    [tMailAccount, mailAccountsQuery],
  );

  return (
    <div
      className={`flex flex-col ${getColSpan(columns, columnsBreakpointSM) ?? ""} ${props.fullWidth ? "w-full" : ""} space-y-3`}>
      <ComboboxAutocomplete
        placeholder={props.placeholder ? props.placeholder : tMailAccount("mailaccount")}
        items={mailAccountItems}
        onSearchTextChange={onMailAccountSearchTextChange}
        isLoading={isLoadingMailAccounts}
        {...props}
      />
      {userError && <HelperText color="failure">{userError.message}</HelperText>}
    </div>
  );
};

import { useWebPageNavigation } from "@whyuz/components";
import { WHYUZ_DYNAMIC_FRONTEND_URL } from "@whyuz/data";
import { Collaboration, CollaborationType } from "@whyuz/services";
import { CollaborationCardButton } from "./CollaborationCardButton";

export interface CollaborationCardSuccessCaseButtonProps {
  collaboration: Collaboration;
}

export const CollaborationCardSuccessCaseButton = ({ collaboration }: CollaborationCardSuccessCaseButtonProps) => {
  const { navigate } = useWebPageNavigation();

  if (collaboration.entityType !== CollaborationType.SuccessCase) {
    return undefined;
  }

  const onButtonPressed = () => {
    navigate(
      WHYUZ_DYNAMIC_FRONTEND_URL.collaborationSuccessCase.view(
        collaboration.entityId as string,
        collaboration.tenant?.id as string,
      ),
    );
  };

  return <CollaborationCardButton collaboration={collaboration} onButtonPressed={onButtonPressed} />;
};

import { Button, Page, useModal } from "@whyuz/components";
import { useTranslation } from "react-i18next";
import { Modal } from "flowbite-react";
import { Error_Code, GQLError, GQLFieldErrorMessageDictionary } from "@whyuz/services";
import { CheckIcon, HandThumbDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { isEmpty } from "@whyuz/utils";

export interface ModalRejectOrCancelApprovalFlowExecutionProps {
  onReject: (reason: string) => void;
  text: "cancel" | "decline";
}
export const ModalRejectOrCancelApprovalFlowExecution = ({
  onReject,
  text,
}: ModalRejectOrCancelApprovalFlowExecutionProps) => {
  const { t: tApproval } = useTranslation("approval");
  const { t } = useTranslation();
  const [reason, setReason] = useState<string>("");
  const { showModal, closeModal, modalProps } = useModal();
  const [error, setError] = useState<GQLError | undefined>(undefined);

  const validateFieldsToRejectApprovalFlowExecution = (): boolean => {
    const validationErrors: GQLFieldErrorMessageDictionary = {};
    setError(undefined);

    if (isEmpty(reason)) {
      validationErrors["reason"] = {
        entity: "approvalFlowExecution",
        field: "reason",
        error: Error_Code.RequiredField,
      };
    }

    const errorsFound = Object.keys(validationErrors).length > 0;
    if (errorsFound) {
      const validationError: GQLError = {
        message: t("errors.validationfailed"),
        fieldErrors: validationErrors,
        isUncontrolledError: false,
      };
      setError(validationError);
    }
    return !errorsFound;
  };
  const handleDeclineApprovalFlowExecution = () => {
    if (validateFieldsToRejectApprovalFlowExecution()) {
      onReject(reason);
      closeModal();
    }
  };

  return (
    <>
      <Button
        leftIcon={<HandThumbDownIcon className="h-4 w-4 stroke-2 self-center" />}
        classname="mr-2"
        type="button"
        size="sm"
        color="failure"
        breakpointSM={false}
        onPointerDown={showModal}>
        {`${t(`buttons.${text}`)}`}
      </Button>
      {modalProps.show && (
        <Modal popup size="md" {...modalProps}>
          <Modal.Header />
          <Modal.Body>
            <div className="text-center space-y-2 ">
              <h3 className="text-lg mb-1 font-normal text-gray-500 dark:text-gray-400">
                {tApproval("declineapprovalflowreason")}
              </h3>
              <Page.FormTextAreaField id="reason" value={reason} error={error} onChange={(e) => setReason(e)} />
              <div className="flex justify-center gap-4">
                <Button
                  color="green"
                  onPointerDown={() => {
                    handleDeclineApprovalFlowExecution();
                  }}
                  leftIcon={<CheckIcon className="h-4 w-4 stroke-2 self-center" />}>
                  {t("buttons.accept")}
                </Button>
                <Button color="gray" onPointerDown={closeModal}>
                  {t("buttons.cancel")}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

import { ContentType, SuccessCaseContentItem } from "@whyuz/services";
import { SuccessCaseQuestionCardFiles } from "./SuccessCaseQuestionCardFile.tsx";
import { SuccessCaseQuestionCardFree } from "./SuccessCaseQuestionCardFree.tsx";
import { SuccessCaseQuestionCardImage } from "./SuccessCaseQuestionCardImage.tsx";
import { SuccessCaseQuestionCardText } from "./SuccessCaseQuestionCardText.tsx";
import { SuccessCaseQuestionCardVideo } from "./SuccessCaseQuestionCardVideo.tsx";

export interface SuccessCaseQuestionItemTypeProps {
  question: SuccessCaseContentItem;
}

export const SuccessCaseQuestionItemType = ({ question }: SuccessCaseQuestionItemTypeProps) => {
  switch (question.contentType) {
    case ContentType.Text:
      return <SuccessCaseQuestionCardText question={question} />;
    case ContentType.Image:
      return <SuccessCaseQuestionCardImage question={question} />;
    case ContentType.Video:
      return <SuccessCaseQuestionCardVideo question={question} />;
    case ContentType.Files:
      return <SuccessCaseQuestionCardFiles question={question} />;
    case ContentType.Free:
      return <SuccessCaseQuestionCardFree question={question} />;
    default:
      return undefined;
  }
};

import {
  InfiniteScrollPaginationCallbackProps,
  Page,
  Pagination,
  useInfiniteScroll,
  useSearch,
} from "@whyuz/components";
import {
  Collaboration,
  FilterExpression,
  FilterExpressionOperator,
  FilterField,
  FilterOperator,
  GQLError,
  SortDirection,
  useCollaborationsLazyQuery,
} from "@whyuz/services";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegHandshake } from "react-icons/fa";
import { CollaborationCard } from "./components/CollaborationCard";

export const CollaborationsManager = () => {
  const { t: tCollaboration } = useTranslation("collaboration");
  const { searchText, setOnChange: setOnSearchTextChange } = useSearch();
  const [error, setError] = useState<GQLError>();
  const [collaborations, setCollaborations] = useState<Collaboration[]>([]);

  const filterExpression: FilterExpression | undefined = useMemo(() => {
    const filterFields: FilterField[] = [];
    const filterExpressions: FilterExpression[] = [];

    if (searchText) {
      filterExpressions.push({
        operator: FilterExpressionOperator.Or,
        fields: [
          { field: "id", operator: FilterOperator.Like, value: "%" + searchText + "%" },
          { field: "title", operator: FilterOperator.Like, value: "%" + searchText + "%" },
          { field: "description", operator: FilterOperator.Like, value: "%" + searchText + "%" },
          { field: "inviterName", operator: FilterOperator.Like, value: "%" + searchText + "%" },
          { field: "customerName", operator: FilterOperator.Like, value: "%" + searchText + "%" },
        ],
      });
    }

    return filterExpressions.length > 0 || filterFields.length > 0
      ? ({
          operator: FilterExpressionOperator.And,
          fields: filterFields.length > 0 ? filterFields : undefined,
          expressions: filterExpressions.length > 0 ? filterExpressions : undefined,
        } as FilterExpression)
      : undefined;
  }, [searchText]);

  const [getCollaborations, { isLoading }] = useCollaborationsLazyQuery({
    variables: {
      filterExpression,
      page: 0,
      pageSize: 10,
      sortField: "createdOn",
      sortDirection: SortDirection.Desc,
    },
    onCompleted: (page) => {
      const collaborationList = (page?.content ?? []) as Collaboration[];
      if (page?.number === 0) {
        setCollaborations(collaborationList);
      } else {
        setCollaborations((prev) => [...prev, ...collaborationList]);
      }
    },
    onError: (error) => {
      setError(error);
    },
  });

  const onScrollReachEnd = useCallback(
    ({ pageNumber, pageSize, setPagination }: InfiniteScrollPaginationCallbackProps) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getCollaborations({
        variables: {
          filterExpression,
          page: pageNumber,
          pageSize,
          sortField: "createdOn",
          sortDirection: SortDirection.Desc,
        },
      }).then((page) => setPagination(page as Pagination));
    },
    [filterExpression, getCollaborations],
  );

  const { lastHTMLElementRef, setPagination } = useInfiniteScroll<HTMLDivElement>({ onScrollReachEnd });

  const reloadCollaborations = useCallback(
    () => onScrollReachEnd({ pageNumber: 0, pageSize: 6, setPagination }),
    [onScrollReachEnd, setPagination],
  );

  useEffect(() => {
    reloadCollaborations();
  }, [searchText, reloadCollaborations, filterExpression]);

  useEffect(() => {
    const onChangeSearch = () => {
      reloadCollaborations();
    };
    setOnSearchTextChange(onChangeSearch);
    return () => {
      setOnSearchTextChange(null);
    };
  }, [reloadCollaborations, setOnSearchTextChange]);

  return (
    <Page isLoading={isLoading} error={error} onErrorClose={() => setError(undefined)}>
      <Page.TitlePanel
        icon={<FaRegHandshake className="w-8 h-8 text-primary-600" />}
        title={tCollaboration("collaborations")}
      />
      <div className={"flex flex-col md:flex-row md:flex-wrap items-center justify-center md:items-center gap-4 p-4"}>
        {collaborations.map((el, index) => (
          <CollaborationCard
            key={el.id as string}
            ref={collaborations.length === index + 1 ? lastHTMLElementRef : undefined}
            collaboration={el}
          />
        ))}
      </div>
    </Page>
  );
};

import { graphql } from "../../codegen/gql";

const successCase = graphql(`
  query successCase($id: UUID!) {
    successCase(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
      subjectHtml
      messageHtml
      amountCents
      owner {
        id
        mail
        firstName
        lastName
      }
      internalCustomerCommercialContact {
        id
        mail
        firstName
        lastName
      }
      internalCustomerServiceContact {
        id
        mail
        firstName
        lastName
      }
      internalApprovalFlowExecution {
        id
        status
        type
        name
        messageHtml
        subjectHtml
        mailAccount {
          id
        }
      }
      customer {
        id
        name
        logoAsset {
          id
          contentType
          originalFilename
          originalExtension
          url
          accessToken
          isPublicReadAllowed
          fileSizeBytes
        }
      }
      mainContact {
        id
        mail
        firstName
        lastName
      }
      customerReward {
        id
        name
        type
        costCents
        additionalInfo
        currency {
          id
          symbol
        }
        status
        granDate
      }
      mainContactReward {
        id
        name
        type
        costCents
        additionalInfo
        currency {
          id
          symbol
        }
        status
        granDate
      }
      customerApprovalFlowExecution {
        id
        status
        type
        name
        messageHtml
        subjectHtml
      }
      customerContentApprovalFlowExecution {
        id
        status
        type
        name
        messageHtml
        subjectHtml
      }
      chat {
        id
      }
      mailAccount {
        id
        name
      }
      contentItems {
        id
        title
        htmlInstructions
        contentType
        contentMaxSizeUnitOfMeasure
        deleted
        position
        contentMaxSize
        htmlTextContent
        assets {
          id
          contentType
          originalFilename
          originalExtension
          url
          accessToken
          isPublicReadAllowed
          fileSizeBytes
        }
        successCase {
          id
        }
      }
    }
  }
`);

const successCases = graphql(`
  query successCases(
    $filterExpression: FilterExpression
    $page: Int
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    successCases(
      filterExpression: $filterExpression
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalPages
      totalElements
      first
      last
      number
      size
      numberOfElements
      content {
        id
        createdOn
        updatedOn
        createdBy
        updatedBy
        deleted
        title
        internalDescription
        status
        customerDescription
        subjectHtml
        messageHtml
        amountCents
        owner {
          id
          mail
          firstName
          lastName
        }
        internalCustomerCommercialContact {
          id
          mail
          firstName
          lastName
        }
        internalCustomerServiceContact {
          id
          mail
          firstName
          lastName
        }
        internalApprovalFlowExecution {
          id
          status
        }
        customer {
          id
          name
          logoAsset {
            id
            contentType
            originalFilename
            originalExtension
            url
            accessToken
            isPublicReadAllowed
            fileSizeBytes
          }
        }
        mailAccount {
          id
          name
        }
        mainContact {
          id
          mail
          firstName
          lastName
        }
        customerReward {
          id
          name
          type
          costCents
          additionalInfo
          currency {
            id
            symbol
          }
          status
          granDate
        }
        mainContactReward {
          id
          name
          type
          costCents
          additionalInfo
          currency {
            id
            symbol
          }
          status
          granDate
        }
        customerApprovalFlowExecution {
          id
          status
        }
        contentItems {
          id
        }
      }
    }
  }
`);

const addSuccessCase = graphql(`
  mutation addSuccessCase($successCase: SuccessCaseInput!, $successCaseTemplateId: UUID) {
    addSuccessCase(successCase: $successCase, successCaseTemplateId: $successCaseTemplateId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
      subjectHtml
      messageHtml
      amountCents
      owner {
        id
        mail
        firstName
        lastName
      }
      internalCustomerCommercialContact {
        id
        mail
        firstName
        lastName
      }
      internalCustomerServiceContact {
        id
        mail
        firstName
        lastName
      }
      internalApprovalFlowExecution {
        id
        status
      }
      customer {
        id
        name
        logoAsset {
          id
          contentType
          originalFilename
          originalExtension
          url
          accessToken
          isPublicReadAllowed
          fileSizeBytes
        }
      }
      mainContact {
        id
        mail
        firstName
        lastName
      }
      customerReward {
        id
        name
        type
        costCents
        additionalInfo
        currency {
          id
          symbol
        }
        status
        granDate
      }
      mainContactReward {
        id
        name
        type
        costCents
        additionalInfo
        currency {
          id
          symbol
        }
        status
        granDate
      }
      customerApprovalFlowExecution {
        id
        status
      }
      chat {
        id
      }
      contentItems {
        id
        title
        htmlInstructions
        contentType
        contentMaxSizeUnitOfMeasure
        deleted
        position
        contentMaxSize
        htmlTextContent
        assets {
          id
          contentType
          originalFilename
          originalExtension
          url
          accessToken
          isPublicReadAllowed
          fileSizeBytes
        }
        successCase {
          id
        }
      }
    }
  }
`);

const updateSuccessCase = graphql(`
  mutation updateSuccessCase($id: UUID!, $successCase: SuccessCaseInput!) {
    updateSuccessCase(id: $id, successCase: $successCase) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
      subjectHtml
      messageHtml
      amountCents
      owner {
        id
        mail
        firstName
        lastName
      }
      mailAccount {
        id
        name
      }
      internalCustomerCommercialContact {
        id
        mail
        firstName
        lastName
      }
      internalCustomerServiceContact {
        id
        mail
        firstName
        lastName
      }
      internalApprovalFlowExecution {
        id
        status
      }
      customer {
        id
        name
        logoAsset {
          id
          contentType
          originalFilename
          originalExtension
          url
          accessToken
          isPublicReadAllowed
          fileSizeBytes
        }
      }

      mainContact {
        id
        mail
        firstName
        lastName
      }
      customerReward {
        id
        name
        type
        costCents
        additionalInfo
        currency {
          id
          symbol
        }
        status
        granDate
      }
      mainContactReward {
        id
        name
        type
        costCents
        additionalInfo
        currency {
          id
          symbol
        }
        status
        granDate
      }
      customerApprovalFlowExecution {
        id
        status
      }
      chat {
        id
      }
      contentItems {
        id
        title
        htmlInstructions
        contentType
        contentMaxSizeUnitOfMeasure
        deleted
        position
        contentMaxSize
        htmlTextContent
        assets {
          id
          contentType
          originalFilename
          originalExtension
          url
          accessToken
          isPublicReadAllowed
          fileSizeBytes
        }
        successCase {
          id
        }
      }
    }
  }
`);

const copySuccessCaseFromTemplate = graphql(`
  mutation copySuccessCaseFromTemplate($id: UUID!, $successCaseTemplateId: UUID!) {
    copySuccessCaseFromTemplate(id: $id, successCaseTemplateId: $successCaseTemplateId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
      subjectHtml
      messageHtml
      amountCents
      owner {
        id
        mail
        firstName
        lastName
      }
      internalCustomerCommercialContact {
        id
        mail
        firstName
        lastName
      }
      internalCustomerServiceContact {
        id
        mail
        firstName
        lastName
      }
      internalApprovalFlowExecution {
        id
        status
      }
      customer {
        id
        name
        logoAsset {
          id
          contentType
          originalFilename
          originalExtension
          url
          accessToken
          isPublicReadAllowed
          fileSizeBytes
        }
      }

      mainContact {
        id
        mail
        firstName
        lastName
      }
      customerReward {
        id
        name
        type
        costCents
        additionalInfo
        currency {
          id
          symbol
        }
        status
        granDate
      }
      mainContactReward {
        id
        name
        type
        costCents
        additionalInfo
        currency {
          id
          symbol
        }
        status
        granDate
      }
      customerApprovalFlowExecution {
        id
        status
      }
      chat {
        id
      }
      contentItems {
        id
        title
        htmlInstructions
        contentType
        contentMaxSizeUnitOfMeasure
        deleted
        position
        contentMaxSize
        htmlTextContent
        assets {
          id
          contentType
          originalFilename
          originalExtension
          url
          accessToken
          isPublicReadAllowed
          fileSizeBytes
        }
        successCase {
          id
        }
      }
    }
  }
`);

const addInternalAppFlowToSuccessCase = graphql(`
  mutation addInternalAppFlowToSuccessCase($id: UUID!, $approvalFlowTemplateId: UUID) {
    addInternalAppFlowToSuccessCase(id: $id, approvalFlowTemplateId: $approvalFlowTemplateId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
      subjectHtml
      messageHtml
      amountCents
      internalApprovalFlowExecution {
        id
        status
        type
        name
        messageHtml
        subjectHtml
        mailAccount {
          id
        }
      }
    }
  }
`);

const addCustomerAppFlowToSuccessCase = graphql(`
  mutation addCustomerAppFlowToSuccessCase($id: UUID!, $tenantId: UUID) {
    addCustomerAppFlowToSuccessCase(id: $id, tenantId: $tenantId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
      subjectHtml
      messageHtml
      amountCents
      customerApprovalFlowExecution {
        id
        status
        type
        name
        messageHtml
        subjectHtml
      }
    }
  }
`);

const deleteInternalAppFlowToSuccessCase = graphql(`
  mutation deleteInternalAppFlowToSuccessCase($id: UUID!) {
    deleteInternalAppFlowToSuccessCase(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
      subjectHtml
      messageHtml
      amountCents
    }
  }
`);

const deleteCustomerAppFlowToSuccessCase = graphql(`
  mutation deleteCustomerAppFlowToSuccessCase($id: UUID!, $tenantId: UUID) {
    deleteCustomerAppFlowToSuccessCase(id: $id, tenantId: $tenantId) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
      subjectHtml
      messageHtml
      amountCents
    }
  }
`);

const deleteSuccessCase = graphql(`
  mutation deleteSuccessCase($id: UUID!) {
    deleteSuccessCase(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
      subjectHtml
      messageHtml
      amountCents
    }
  }
`);

const sendSuccessCaseInvitation = graphql(`
  mutation sendSuccessCaseInvitation($id: UUID!) {
    sendSuccessCaseInvitation(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
      subjectHtml
      messageHtml
      amountCents
    }
  }
`);

const resendSuccessCaseInvitation = graphql(`
  mutation resendSuccessCaseInvitation($id: UUID!) {
    resendSuccessCaseInvitation(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
      subjectHtml
      messageHtml
      amountCents
    }
  }
`);

const launchSuccessCaseInternalApprovalFlow = graphql(`
  mutation launchSuccessCaseInternalApprovalFlow($id: UUID!) {
    launchSuccessCaseInternalApprovalFlow(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
      subjectHtml
      messageHtml
      amountCents
      internalApprovalFlowExecution {
        id
        status
        type
        name
        messageHtml
        subjectHtml
      }
    }
  }
`);

const cancelSuccessCase = graphql(`
  mutation cancelSuccessCase($id: UUID!) {
    cancelSuccessCase(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
    }
  }
`);

const draftStatusSuccessCase = graphql(`
  mutation draftStatusSuccessCase($id: UUID!) {
    draftStatusSuccessCase(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
    }
  }
`);

const successCaseAskForChanges = graphql(`
  mutation successCaseAskForChanges($id: UUID!, $feedback: String!) {
    successCaseAskForChanges(id: $id, feedback: $feedback) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
    }
  }
`);

const successCaseAcceptReview = graphql(`
  mutation successCaseAcceptReview($id: UUID!) {
    successCaseAcceptReview(id: $id) {
      id
      createdOn
      updatedOn
      createdBy
      updatedBy
      deleted
      title
      internalDescription
      status
      customerDescription
    }
  }
`);
export const SuccessCaseGQL = {
  query: { successCase, successCases },
  mutation: {
    addSuccessCase,
    updateSuccessCase,
    deleteSuccessCase,
    copySuccessCaseFromTemplate,
    addInternalAppFlowToSuccessCase,
    addCustomerAppFlowToSuccessCase,
    deleteInternalAppFlowToSuccessCase,
    deleteCustomerAppFlowToSuccessCase,
    sendSuccessCaseInvitation,
    resendSuccessCaseInvitation,
    launchSuccessCaseInternalApprovalFlow,
    cancelSuccessCase,
    draftStatusSuccessCase,
    successCaseAskForChanges,
    successCaseAcceptReview,
  },
};

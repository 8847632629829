import { HandThumbUpIcon } from "@heroicons/react/24/outline";
import {
  Button,
  DebouncedTextInput,
  FlowbiteModalThemes,
  InfiniteScrollPaginationCallbackProps,
  Page,
  Pagination,
  useInfiniteScroll,
  useModal,
} from "@whyuz/components";
import { RIGHT_BUTTON } from "@whyuz/data";
import {
  ApprovalFlowTemplate,
  ApprovalFlowType,
  FilterExpression,
  FilterExpressionOperator,
  FilterField,
  FilterOperator,
  GQLError,
  SortDirection,
  useApprovalFlowTemplatesLazyQuery,
} from "@whyuz/services";
import { Modal } from "flowbite-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TbTemplate } from "react-icons/tb";
import { ApprovalFlowTemplateCardModal } from "./ApprovalFlowTemplateCardModal.tsx";
import { OneApprovalFlowTemplateModal } from "./OneApprovalFlowTemplateModal.tsx";
import { HiSearch } from "react-icons/hi";
import { FlowbiteIcon } from "@whyuz/types";

interface Props {
  handleUseTemplate: (approvalFlowTemplateToUse: ApprovalFlowTemplate) => void;
  approvalFlowType: ApprovalFlowType;
  disabled?: boolean;
}

export const ApprovalFlowTemplatesModal = ({ handleUseTemplate, approvalFlowType, disabled = false }: Props) => {
  const { t } = useTranslation();
  const { t: tApprovalFlow } = useTranslation("approval");
  const { showModal, closeModal, modalProps } = useModal();
  const [searchText, setSearchText] = useState<string | null>();
  const [approvalFlowTemplates, setApprovalFlowTemplates] = useState<ApprovalFlowTemplate[]>([]);
  const [error, setError] = useState<GQLError>();
  const [openTemplate, setOpenTemplate] = useState<ApprovalFlowTemplate | undefined>();

  const filterExpression: FilterExpression | undefined = useMemo(() => {
    const filterExpressions: FilterField[] = [
      { field: "type", operator: FilterOperator.Equal, value: approvalFlowType },
    ];

    if (searchText) {
      filterExpressions.push({ field: "name", operator: FilterOperator.Like, value: "%" + searchText + "%" });
    }

    return filterExpressions.length > 0
      ? ({
          operator: FilterExpressionOperator.And,
          fields: filterExpressions.length > 0 ? filterExpressions : undefined,
          expressions: undefined,
        } as FilterExpression)
      : undefined;
  }, [searchText, approvalFlowType]);

  const [getApprovalFlowTemplates, { isLoading }] = useApprovalFlowTemplatesLazyQuery({
    variables: {
      filterExpression,
      page: 0,
      pageSize: 10,
      sortField: "createdOn",
      sortDirection: SortDirection.Desc,
    },
    onCompleted: (page) => {
      const approvalFlowTemplateList = (page?.content ?? []) as ApprovalFlowTemplate[];
      if (page?.number === 0) {
        setApprovalFlowTemplates(approvalFlowTemplateList);
      } else {
        setApprovalFlowTemplates((prev) => [...prev, ...approvalFlowTemplateList]);
      }
    },
    onError: (error) => {
      setError(error);
    },
  });

  const onScrollReachEnd = useCallback(
    ({ pageNumber, pageSize, setPagination }: InfiniteScrollPaginationCallbackProps) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getApprovalFlowTemplates({
        variables: {
          filterExpression,
          page: pageNumber,
          pageSize,
          sortField: "createdOn",
          sortDirection: SortDirection.Desc,
        },
      }).then((page) => setPagination(page as Pagination));
    },
    [filterExpression, getApprovalFlowTemplates],
  );

  const { lastHTMLElementRef, setPagination } = useInfiniteScroll<HTMLDivElement>({ onScrollReachEnd });

  const reloadApprovalFlowTemplates = useCallback(
    () => onScrollReachEnd({ pageNumber: 0, pageSize: 6, setPagination }),
    [onScrollReachEnd, setPagination],
  );

  useEffect(() => {
    reloadApprovalFlowTemplates();
  }, [searchText, reloadApprovalFlowTemplates, filterExpression]);

  const { showModal: showTemplate, closeModal: closeTemplate, modalProps: modalTemplateProps } = useModal();

  const openModalItems = (item: ApprovalFlowTemplate) => {
    setOpenTemplate(() => item);
    showTemplate();
  };

  const onUseTemplate = (approvalFlowTemplateToUse: ApprovalFlowTemplate) => {
    handleUseTemplate(approvalFlowTemplateToUse);
    closeModal();
  };

  const onCloseTemplateModal = () => {
    setOpenTemplate(undefined);
    closeTemplate();
  };

  return (
    <>
      <Button
        leftIcon={<TbTemplate className="h-4 w-4 stroke-2 self-center" />}
        classname="mr-2"
        type="button"
        size="sm"
        breakpointSM={false}
        disabled={disabled}
        onPointerDown={showModal}>
        {t("buttons.useexistingtemplate")}
      </Button>
      {modalProps.show && (
        <Modal popup {...modalProps} onClose={closeModal} id="modalRoot" theme={FlowbiteModalThemes.complete}>
          {modalTemplateProps.show && openTemplate && (
            <OneApprovalFlowTemplateModal
              {...modalTemplateProps}
              onClose={onCloseTemplateModal}
              approvalFlowTemplate={openTemplate}
              onUseTemplate={onUseTemplate}
            />
          )}
          <Modal.Header>
            <Page.Title
              classname="w-3/5 lg:w-full"
              icon={<HandThumbUpIcon className="w-8 h-8 text-primary-600" />}
              title={tApprovalFlow("approvals")}
            />
          </Modal.Header>
          <Modal.Body id="modal-nested">
            <Page.LoadingOrError isLoading={isLoading} error={error}>
              <div className="flex flex-col w-full h-full md:h-[60vh] items-start space-y-2">
                <DebouncedTextInput
                  type="search"
                  onChange={(e) => setSearchText(e)}
                  value={searchText}
                  id="ApprovalFlowTemplateModalSearchText"
                  placeholder={t("placeholders.searchtemplates")}
                  className="w-full"
                  rightIcon={HiSearch as FlowbiteIcon}
                />
                <div className="overflow-hidden overflow-y-auto flex-1 w-full space-y-2">
                  {approvalFlowTemplates.length === 0 && (
                    <span className="text-xs pl-2 italic text-gray-500 dark:text-gray-400">
                      {t("errors.itemsnotfound")}
                    </span>
                  )}
                  {approvalFlowTemplates.length > 0 &&
                    approvalFlowTemplates.map((el, index) => {
                      if (approvalFlowTemplates.length === index + 1) {
                        return (
                          <ApprovalFlowTemplateCardModal
                            onPointerDown={(e) => {
                              if (e.button !== RIGHT_BUTTON) {
                                openModalItems(el);
                              }
                            }}
                            key={el.id as string}
                            ref={lastHTMLElementRef}
                            approvalFlowTemplate={el}
                          />
                        );
                      } else {
                        return (
                          <ApprovalFlowTemplateCardModal
                            onClick={() => openModalItems(el)}
                            key={el.id as string}
                            approvalFlowTemplate={el}
                          />
                        );
                      }
                    })}
                </div>
              </div>
            </Page.LoadingOrError>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

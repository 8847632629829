import { TextInput, TextInputProps } from "flowbite-react";
import { useRef, useState } from "react";
import { Page } from "@whyuz/components";
import { GQLError } from "@whyuz/services";
import { useTranslation } from "react-i18next";

export interface ApprovalFlowAddUserEmailInputProps extends Omit<TextInputProps, "onChange"> {
  onChange: (debouncedText: string | null | undefined) => void;
  errors: GQLError | undefined;
}

export const ApprovalFlowAddUserEmailInput = ({ onChange, errors, ...props }: ApprovalFlowAddUserEmailInputProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const isFocused = useRef(false); // Track if the input is focused

  const handleFocus = () => {
    isFocused.current = true;
  };

  const handleBlur = () => {
    isFocused.current = false;
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && isFocused.current && value !== "") {
      onChange(value); // Execute onChange when Enter is pressed and input is focused
      setValue("");
    }
  };

  return (
    <Page.FormCustomEditableField id="userId" label={t("word.email")} error={errors}>
      <TextInput
        ref={inputRef}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyUp={(e) => handleKeyUp(e)}
        {...props}
      />
    </Page.FormCustomEditableField>
  );
};

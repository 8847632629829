import { Error404PageNotFound, Page, useWebPageNavigation } from "@whyuz/components";
import { WHYUZ_URL_PARAMETERS } from "@whyuz/data";
import {
  Collaboration,
  GQLError,
  GQLErrorStatusCode,
  useCollaborationByTokenLazyQuery,
  useCollaborationLazyQuery,
  useUser,
} from "@whyuz/services";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { CollaborationCard } from "./components/CollaborationCard";

export const CollaborationManager = () => {
  const { t: tCollaboration } = useTranslation("collaboration");
  const { navigate } = useWebPageNavigation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get(WHYUZ_URL_PARAMETERS.TOKEN);
  const userCtx = useUser();
  const [error, setError] = useState<GQLError>();
  const [collaboration, setCollaboration] = useState<Collaboration>();
  const [collaborationLazyQuery, { hasEverBeenExecuted: hasEverBeenExecutedId, isLoading: isLoadingId }] =
    useCollaborationLazyQuery();
  const [collaborationByTokenLazyQuery, { hasEverBeenExecuted: hasEverBeenExecutedToken, isLoading: isLoadingToken }] =
    useCollaborationByTokenLazyQuery();

  useEffect(() => {
    if (id) {
      collaborationLazyQuery({ variables: { id } })
        .then((collaboration) =>
          collaboration
            ? setCollaboration(collaboration)
            : setError({
                isUncontrolledError: true,
                statusCode: GQLErrorStatusCode.ENTITY_NOT_FOUND,
                message: tCollaboration("collaboration") + " " + String(id),
                fieldErrors: {},
              }),
        )
        .catch((error: GQLError) => setError(error));
    } else if (token) {
      collaborationByTokenLazyQuery({ variables: { token } })
        .then((collaboration) =>
          collaboration
            ? setCollaboration(collaboration)
            : setError({
                isUncontrolledError: true,
                statusCode: GQLErrorStatusCode.ENTITY_NOT_FOUND,
                message: tCollaboration("collaboration") + " " + String(id),
                fieldErrors: {},
              }),
        )
        .catch((error: GQLError) => setError(error));
    }
  }, [collaborationLazyQuery, id, token, navigate, tCollaboration, collaborationByTokenLazyQuery]);

  if (hasEverBeenExecutedId || hasEverBeenExecutedToken) {
    if (!collaboration || (collaboration && !userCtx.isUserSuperAdmin && collaboration.deleted)) {
      return <Error404PageNotFound />;
    }
  } else if (!id && !token) {
    return <Error404PageNotFound />;
  }

  return (
    <Page isLoading={isLoadingId || isLoadingToken} error={error} onErrorClose={() => setError(undefined)}>
      {collaboration && (
        <div
          className={
            "flex flex-col md:flex-row items-start justify-between md:items-center md:space-x-4 space-y-4 md:space-y-0 p-4"
          }>
          <CollaborationCard collaboration={collaboration} />
        </div>
      )}
    </Page>
  );
};

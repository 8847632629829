import {
  InfiniteScrollPaginationCallbackProps,
  Page,
  Pagination,
  useInfiniteScroll,
  useModal,
} from "@whyuz/components";
import { useDebounce } from "@whyuz/hooks";
import {
  FilterExpression,
  FilterExpressionOperator,
  FilterField,
  FilterOperator,
  GQLError,
  Language,
  MailTemplateLanguage,
  MailTemplateType,
  SortDirection,
  useMailTemplateLanguagesLazyQuery,
  useUser,
} from "@whyuz/services";
import { FlowbiteIcon } from "@whyuz/types";
import { TextInput } from "flowbite-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiSearch } from "react-icons/hi";
import { LanguageCombobox } from "../../Languages/components/LanguageCombobox.tsx";
import { MailTemplateLanguageCard } from "../Modal/MailTempalteLanguageCard.tsx";
import { MailTemplateLanguageModalEdit } from "../Modal/MailTemplateLanguageModalEdit.tsx";

interface MailTemplatesLanguagesInEmailModalProps {
  handleUseTemplate: (mailTemplateLanguage: MailTemplateLanguage) => void;
  mailTemplateType: MailTemplateType;
}

export const MailTemplatesLanguagesInEmailModal = ({
  handleUseTemplate,
  mailTemplateType,
}: MailTemplatesLanguagesInEmailModalProps) => {
  const { t } = useTranslation();
  const userCtx = useUser();
  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce<string>(searchText, 1000);
  const [selectedLanguageId, setSelectedLanguageId] = useState<string | undefined>(undefined);
  const [mailTemplateLanguages, setMailTemplateLanguages] = useState<MailTemplateLanguage[]>([]);
  const [error, setError] = useState<GQLError>();
  const [modalOneLanguage, setModalOneLanguage] = useState<MailTemplateLanguage | undefined>(undefined);
  const filterExpression: FilterExpression | undefined = useMemo(() => {
    const filterExpressions: FilterField[] = [
      { field: "mailTemplate.type", operator: FilterOperator.Equal, value: mailTemplateType },
      { field: "mailTemplate.enabled", operator: FilterOperator.Equal, value: "true" },
    ];
    const expressions: FilterExpression[] = [];
    if (!userCtx.isUserSuperAdmin) {
      filterExpressions.push({ field: "deleted", operator: FilterOperator.Equal, value: "false" });
    }

    if (debouncedSearchText !== "") {
      expressions.push({
        operator: FilterExpressionOperator.Or,
        fields: [
          { field: "title", operator: FilterOperator.Like, value: "%" + debouncedSearchText + "%" },
          { field: "mailTemplate.name", operator: FilterOperator.Like, value: "%" + debouncedSearchText + "%" },
        ],
      });
    }

    if (selectedLanguageId && selectedLanguageId !== "") {
      filterExpressions.push({ field: "languageId", operator: FilterOperator.Equal, value: selectedLanguageId });
    }

    return filterExpressions.length > 0 || expressions.length > 0
      ? ({
          operator: FilterExpressionOperator.And,
          fields: filterExpressions,
          expressions: expressions,
        } as FilterExpression)
      : undefined;
  }, [mailTemplateType, userCtx.isUserSuperAdmin, debouncedSearchText, selectedLanguageId]);

  const [getMailTemplatesLanguages, { isLoading }] = useMailTemplateLanguagesLazyQuery({
    variables: {
      filterExpression,
      page: 0,
      pageSize: 10,
      sortField: "createdOn",
      sortDirection: SortDirection.Desc,
    },
    onCompleted: (page) => {
      const mailTemplateLanguageList = (page?.content ?? []) as MailTemplateLanguage[];
      if (page?.number === 0) {
        setMailTemplateLanguages(mailTemplateLanguageList);
      } else {
        setMailTemplateLanguages((prev) => [...prev, ...mailTemplateLanguageList]);
      }
    },
    onError: (error) => {
      setError(error);
    },
  });

  const onScrollReachEnd = useCallback(
    ({ pageNumber, pageSize, setPagination }: InfiniteScrollPaginationCallbackProps) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getMailTemplatesLanguages({
        variables: {
          filterExpression,
          page: pageNumber,
          pageSize,
          sortField: "createdOn",
          sortDirection: SortDirection.Desc,
        },
      }).then((page) => setPagination(page as Pagination));
    },
    [filterExpression, getMailTemplatesLanguages],
  );

  const { lastHTMLElementRef, setPagination } = useInfiniteScroll<HTMLDivElement>({ onScrollReachEnd });

  const reloadMailTemplateLanguages = useCallback(
    () => onScrollReachEnd({ pageNumber: 0, pageSize: 6, setPagination }),
    [onScrollReachEnd, setPagination],
  );

  useEffect(() => {
    reloadMailTemplateLanguages();
  }, [debouncedSearchText, reloadMailTemplateLanguages, filterExpression]);

  const { showModal: showModalLanguage, closeModal: closeModalLanguage, modalProps: modalPropsLanguage } = useModal();

  const openModalItems = (item: MailTemplateLanguage | undefined) => {
    setModalOneLanguage(() => item);
    showModalLanguage();
  };

  const onUseTemplate = (mailTemplateLanguageToUse: MailTemplateLanguage) => {
    handleUseTemplate(mailTemplateLanguageToUse);
  };

  const onEditTemplate = (mailTemplateLanguageEdited: MailTemplateLanguage) => {
    setMailTemplateLanguages((prev) => {
      return prev.reduce((acc, next) => {
        if (next.id === mailTemplateLanguageEdited.id) {
          return [...acc, mailTemplateLanguageEdited];
        } else {
          return [...acc, next];
        }
      }, [] as MailTemplateLanguage[]);
    });
  };
  const onDeleteTemplate = () => {
    reloadMailTemplateLanguages();
  };

  return (
    <>
      {modalPropsLanguage.show && modalOneLanguage && (
        <MailTemplateLanguageModalEdit
          {...modalPropsLanguage}
          onClose={() => {
            closeModalLanguage();
            setModalOneLanguage(undefined);
          }}
          mailTemplateLanguage={modalOneLanguage}
          onUseTemplate={onUseTemplate}
          onEditTemplate={onEditTemplate}
          onDeleteTemplate={onDeleteTemplate}
          mailTemplateType={mailTemplateType}
        />
      )}

      <Page.LoadingOrError isLoading={isLoading} error={error}>
        <div className="flex flex-col w-full h-[50vh] space-y-2 items-start">
          <div className="w-full">
            <LanguageCombobox
              id="languageId"
              selectedItemId={selectedLanguageId}
              placeholder={t("word.language")}
              error={error}
              onChange={(language: Language | null) => {
                setSelectedLanguageId(language ? language.id : undefined);
              }}
            />
          </div>
          <TextInput
            className="w-full my-2"
            placeholder={t("placeholders.searchtemplates")}
            rightIcon={HiSearch as FlowbiteIcon}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
          />

          <div className="overflow-hidden overflow-y-auto flex-1 w-full space-y-2">
            {mailTemplateLanguages.length === 0 && (
              <span className="text-xs pl-2 italic text-gray-500 dark:text-gray-400">{t("errors.itemsnotfound")}</span>
            )}
            {mailTemplateLanguages.map((el, index) => (
              <MailTemplateLanguageCard
                onClick={() => openModalItems(el)}
                key={el?.updatedOn as string}
                ref={mailTemplateLanguages.length === index + 1 ? lastHTMLElementRef : undefined}
                mailTemplateLanguage={el}
              />
            ))}
          </div>
        </div>
      </Page.LoadingOrError>
    </>
  );
};

import { ApprovalFlowExecutionStatus } from "@whyuz/services";
import { Badge } from "flowbite-react";
import { useApprovalFlowStatusTranslations } from "../hooks/useApprovalFlowStatus.tsx";

interface ApprovalFlowExecutionBadgeProps {
  status?: ApprovalFlowExecutionStatus;
}
export const ApprovalFlowExecutionBadge = ({ status }: ApprovalFlowExecutionBadgeProps) => {
  const approvalFlowStatusTranslations = useApprovalFlowStatusTranslations();

  return (
    <Badge className="flex w-fit" color={approvalFlowStatusTranslations[status as ApprovalFlowExecutionStatus].color}>
      {approvalFlowStatusTranslations[status as ApprovalFlowExecutionStatus].name}
    </Badge>
  );
};

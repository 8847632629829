import { FlowbiteTimelineIconTheme, FlowbiteTimelinePointIconTheme, TimelineItemLocaleDate } from "@whyuz/components";
import { ApprovalFlowExecutionStatus, ApprovalFlowStepExecutionStatus } from "@whyuz/services";
import { isEmpty } from "@whyuz/utils";
import { Timeline } from "flowbite-react";
import { FC, SVGProps } from "react";
import { Trans, useTranslation } from "react-i18next";
import { HiOutlineCheck, HiOutlineClock, HiOutlinePause, HiOutlinePlus, HiOutlineX } from "react-icons/hi";
import { HiOutlineRocketLaunch, HiStopCircle } from "react-icons/hi2";
import { useApprovalFlowStatusTranslations } from "../../ApprovalFlows";
import { useApprovalFlowExecutionBuilderContext } from "../index.ts";

//TODO Make a complete refactor of that component, not only for AppExec but create a component TimeLine and TimeLineItem un components library
export const ApprovalFlowExecutionHistory = () => {
  const { t: tApproval } = useTranslation("approval");
  const { approvalFlowExecution } = useApprovalFlowExecutionBuilderContext();

  const executionUsers = approvalFlowExecution?.steps?.flatMap((step) => step?.users);
  const approvedOrRejectedUsers = executionUsers
    ?.filter((executionUser) => executionUser?.sentDate)
    .sort((a, b) => new Date(a?.updatedOn as string).getTime() - new Date(b?.updatedOn as string).getTime());

  const ApprovalFlowStatusTranslations = useApprovalFlowStatusTranslations();

  const iconPerStatus: Record<ApprovalFlowStepExecutionStatus, FC<SVGProps<SVGSVGElement>>> = {
    [ApprovalFlowStepExecutionStatus.NotNeeded]: HiStopCircle as FC<SVGProps<SVGSVGElement>>,
    [ApprovalFlowStepExecutionStatus.Approved]: HiOutlineCheck as FC<SVGProps<SVGSVGElement>>,
    [ApprovalFlowStepExecutionStatus.Rejected]: HiOutlineX as FC<SVGProps<SVGSVGElement>>,
    [ApprovalFlowStepExecutionStatus.NotRequested]: HiOutlinePause as FC<SVGProps<SVGSVGElement>>,
    [ApprovalFlowStepExecutionStatus.Pending]: HiOutlineClock as FC<SVGProps<SVGSVGElement>>,
    [ApprovalFlowStepExecutionStatus.ApprovalCancelled]: HiOutlineX as FC<SVGProps<SVGSVGElement>>,
  };

  const iconColorPerStatus: Record<ApprovalFlowStepExecutionStatus, FlowbiteTimelinePointIconTheme> = {
    [ApprovalFlowStepExecutionStatus.NotNeeded]: FlowbiteTimelineIconTheme.grayIcon,
    [ApprovalFlowStepExecutionStatus.Approved]: FlowbiteTimelineIconTheme.greenIcon,
    [ApprovalFlowStepExecutionStatus.Rejected]: FlowbiteTimelineIconTheme.redIcon,
    [ApprovalFlowStepExecutionStatus.NotRequested]: FlowbiteTimelineIconTheme.grayIcon,
    [ApprovalFlowStepExecutionStatus.Pending]: FlowbiteTimelineIconTheme.primaryIcon,
    [ApprovalFlowStepExecutionStatus.ApprovalCancelled]: FlowbiteTimelineIconTheme.redIcon,
  };

  return (
    <Timeline horizontal={false} className="ml-4">
      {approvalFlowExecution?.cancelledDate && (
        <TimelineItemLocaleDate
          iconTheme={FlowbiteTimelineIconTheme.redIcon}
          icon={HiOutlineX}
          date={new Date(approvalFlowExecution.cancelledDate as string)}>
          <Trans t={tApproval} i18nKey={`statuslog.CANCELLED`} values={{ email: approvalFlowExecution?.cancelledBy }} />
        </TimelineItemLocaleDate>
      )}
      {approvalFlowExecution?.rejectedDate && (
        <TimelineItemLocaleDate
          iconTheme={FlowbiteTimelineIconTheme.redIcon}
          icon={HiOutlineX}
          date={new Date(approvalFlowExecution.rejectedDate as string)}>
          <Trans t={tApproval} i18nKey={`statuslog.REJECTED`} values={{ email: approvalFlowExecution?.rejectedBy }} />
        </TimelineItemLocaleDate>
      )}
      {approvalFlowExecution?.approvedDate && (
        <TimelineItemLocaleDate
          iconTheme={FlowbiteTimelineIconTheme.greenIcon}
          icon={HiOutlineCheck}
          date={new Date(approvalFlowExecution.approvedDate as string)}>
          {ApprovalFlowStatusTranslations[ApprovalFlowExecutionStatus.Approved].name}
        </TimelineItemLocaleDate>
      )}
      {approvedOrRejectedUsers?.map((user, i) => (
        <TimelineItemLocaleDate
          key={i}
          iconTheme={iconColorPerStatus[user?.status as ApprovalFlowStepExecutionStatus]}
          icon={user?.status ? iconPerStatus[user?.status] : undefined}
          date={new Date(user?.updatedOn as string)}>
          <Trans
            t={tApproval}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
            i18nKey={`statuslog.${user?.status}` as any}
            values={{ email: isEmpty(user?.name) ? user?.email : user?.name }}
          />
        </TimelineItemLocaleDate>
      ))}
      {approvalFlowExecution?.launchedDate && (
        <TimelineItemLocaleDate
          icon={HiOutlineRocketLaunch}
          date={new Date(approvalFlowExecution?.launchedDate as string)}>
          <Trans t={tApproval} i18nKey={`statuslog.LAUNCHED`} values={{ email: approvalFlowExecution?.launchedBy }} />
        </TimelineItemLocaleDate>
      )}
      <TimelineItemLocaleDate icon={HiOutlinePlus} date={new Date(approvalFlowExecution?.createdOn as string)}>
        <Trans t={tApproval} i18nKey={`statuslog.CREATED`} values={{ email: approvalFlowExecution?.createdBy }} />
      </TimelineItemLocaleDate>
    </Timeline>
  );
};

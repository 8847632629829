import { Button } from "@whyuz/components";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "@heroicons/react/24/outline";
import { ApprovalFlowTemplatesModal } from "../../../ApprovalFlowTemplateBuilder";
import { ApprovalFlowType } from "@whyuz/services";

export interface AddApprovalFlowProps {
  onAddSuccessCaseApprovalFlow?: (approvalFlowTemplateId?: string) => void;
  internal: boolean;
  addFunctionsEnabled: boolean;
}

export const AddApprovalFlowToSuccessCase = ({
  internal,
  onAddSuccessCaseApprovalFlow,
  addFunctionsEnabled,
}: AddApprovalFlowProps) => {
  const { t: tSuccessCase } = useTranslation("successCaseTemplate");

  return (
    <div className="flex flex-col items-center space-y-2">
      <div className="text-sm text-gray-600 dark:text-gray-400 w-full text-wrap text-center">
        {internal
          ? tSuccessCase("successcasesubforms.internalapprovalflow.appflowcreation")
          : tSuccessCase("successcasesubforms.customerapprovalflow.appflowcreation")}
      </div>
      <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row items-center md:justify-around">
        <Button
          leftIcon={<PlusIcon className="h-4 w-4 stroke-2 self-center" />}
          classname="mr-2"
          type="button"
          size="sm"
          breakpointSM={false}
          disabled={!addFunctionsEnabled}
          onPointerDown={onAddSuccessCaseApprovalFlow}>
          {tSuccessCase("successcasesubforms.createone")}
        </Button>
        {internal && (
          <ApprovalFlowTemplatesModal
            disabled={!addFunctionsEnabled}
            approvalFlowType={ApprovalFlowType.SuccessCaseExecution}
            handleUseTemplate={(approvalFlowTemplateToUse) =>
              onAddSuccessCaseApprovalFlow
                ? onAddSuccessCaseApprovalFlow(approvalFlowTemplateToUse.id as string)
                : undefined
            }
          />
        )}
      </div>
    </div>
  );
};

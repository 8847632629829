import { Login, SuccessCaseContentEditorManager, UserLicenseManager } from "@whyuz/app-components";
import { Error404PageNotFound } from "@whyuz/components";
import { WHYUZ_FRONTEND_URL } from "@whyuz/data";
import { useUser } from "@whyuz/services";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { CollaborationManager } from "../Collaborations/CollaborationManager.tsx";
import { CollaborationsManager } from "../Collaborations/CollaborationsManager.tsx";
import { CollaborationSuccessCaseManager } from "../SuccessCase/CollaborationSuccessCaseManager.tsx";
import { UserApprovalManager } from "../UserApproval";
const AppLayout = lazy(() => import("./components/AppLayout.tsx").then(({ AppLayout }) => ({ default: AppLayout })));

export const App = () => {
  const userCtx = useUser();

  if (!userCtx.isUserAuthenticated) {
    return <Login />;
  }

  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path={WHYUZ_FRONTEND_URL.HOME} element={<CollaborationsManager />} />
        <Route path={WHYUZ_FRONTEND_URL.COLLABORATION} element={<CollaborationManager />} />
        <Route path={WHYUZ_FRONTEND_URL.COLLABORATION_ID} element={<CollaborationManager />} />
        <Route path={WHYUZ_FRONTEND_URL.COLLABORATIONS} element={<CollaborationsManager />} />
        <Route path={WHYUZ_FRONTEND_URL.SUCCESS_CASE_CONTENT_EDITOR} element={<SuccessCaseContentEditorManager />} />
        <Route path={WHYUZ_FRONTEND_URL.SUCCESS_CASE_CONTENT_EDITOR_ID} element={<SuccessCaseContentEditorManager />} />
        <Route path={WHYUZ_FRONTEND_URL.COLLABORATION_SUCCESS_CASE} element={<CollaborationSuccessCaseManager />} />
        <Route path={WHYUZ_FRONTEND_URL.COLLABORATION_SUCCESS_CASE_ID} element={<CollaborationSuccessCaseManager />} />
        <Route path={WHYUZ_FRONTEND_URL.USER_LICENSE_ID} element={<UserLicenseManager />} />
        <Route path={WHYUZ_FRONTEND_URL.USER_LICENSE} element={<UserLicenseManager />} />
        <Route path={WHYUZ_FRONTEND_URL.USER_APPROVAL} element={<UserApprovalManager />} />
        <Route path="*" element={<Error404PageNotFound />} />
      </Route>
    </Routes>
  );
};

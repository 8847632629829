import {
  Collaboration,
  CollaborationByTokenQueryVariables,
  CollaborationPage,
  CollaborationQueryVariables,
  CollaborationsQueryVariables,
} from "../../codegen/graphql.ts";
import { CollaborationGQL } from "../../queries";
import { GQLQueryContext } from "../../types";
import { useGQLLazyQuery, useGQLQuery } from "../useGQLQuery.ts";

export const useCollaborationsLazyQuery = (
  queryContext?: GQLQueryContext<CollaborationPage, CollaborationsQueryVariables>,
) =>
  useGQLLazyQuery<CollaborationPage, CollaborationsQueryVariables>(CollaborationGQL.query.collaborations, queryContext);

export const useCollaborationsQuery = (
  queryContext?: GQLQueryContext<CollaborationPage, CollaborationsQueryVariables>,
) => useGQLQuery<CollaborationPage, CollaborationsQueryVariables>(CollaborationGQL.query.collaborations, queryContext);

export const useCollaborationLazyQuery = (queryContext?: GQLQueryContext<Collaboration, CollaborationQueryVariables>) =>
  useGQLLazyQuery<Collaboration, CollaborationQueryVariables>(CollaborationGQL.query.collaboration, queryContext);

export const useCollaborationQuery = (queryContext?: GQLQueryContext<Collaboration, CollaborationQueryVariables>) =>
  useGQLQuery<Collaboration, CollaborationQueryVariables>(CollaborationGQL.query.collaboration, queryContext);

export const useCollaborationByTokenLazyQuery = (
  queryContext?: GQLQueryContext<Collaboration, CollaborationByTokenQueryVariables>,
) =>
  useGQLLazyQuery<Collaboration, CollaborationByTokenQueryVariables>(
    CollaborationGQL.query.collaborationByToken,
    queryContext,
  );

export const useCollaborationByTokenQuery = (
  queryContext?: GQLQueryContext<Collaboration, CollaborationByTokenQueryVariables>,
) =>
  useGQLQuery<Collaboration, CollaborationByTokenQueryVariables>(
    CollaborationGQL.query.collaborationByToken,
    queryContext,
  );

import { useGQLLazyQuery, useGQLMutation, useGQLQuery } from "../";
import { GQLQueryContext } from "../../types";

import {
  AddMailTemplateMutationVariables,
  DeleteMailTemplateMutationVariables,
  MailTemplate,
  MailTemplatePage,
  MailTemplateQueryVariables,
  MailTemplatesQueryVariables,
  UpdateMailTemplateMutationVariables,
} from "../../codegen/graphql";
import { MailTemplateGQL } from "../../queries";

export const useAddMailTemplateMutation = (
  mutationContext?: GQLQueryContext<MailTemplate, AddMailTemplateMutationVariables>,
) =>
  useGQLMutation<MailTemplate, AddMailTemplateMutationVariables>(
    MailTemplateGQL.mutation.addMailTemplate,
    mutationContext,
  );


export const useUpdateMailTemplateMutation = (
  mutationContext?: GQLQueryContext<MailTemplate, UpdateMailTemplateMutationVariables>,
) =>
  useGQLMutation<MailTemplate, UpdateMailTemplateMutationVariables>(
    MailTemplateGQL.mutation.updateMailTemplate,
    mutationContext,
  );

export const useDeleteMailTemplateMutation = (
  mutationContext?: GQLQueryContext<MailTemplate, DeleteMailTemplateMutationVariables>,
) =>
  useGQLMutation<MailTemplate, DeleteMailTemplateMutationVariables>(
    MailTemplateGQL.mutation.deleteMailTemplate,
    mutationContext,
  );

export const useMailTemplatesLazyQuery = (
  queryContext?: GQLQueryContext<MailTemplatePage, MailTemplatesQueryVariables>,
) => useGQLLazyQuery<MailTemplatePage, MailTemplatesQueryVariables>(MailTemplateGQL.query.mailTemplates, queryContext);

export const useMailTemplatesQuery = (queryContext?: GQLQueryContext<MailTemplatePage, MailTemplatesQueryVariables>) =>
  useGQLQuery<MailTemplatePage, MailTemplatesQueryVariables>(MailTemplateGQL.query.mailTemplates, queryContext);

export const useMailTemplateLazyQuery = (queryContext?: GQLQueryContext<MailTemplate, MailTemplateQueryVariables>) =>
  useGQLLazyQuery<MailTemplate, MailTemplateQueryVariables>(MailTemplateGQL.query.mailTemplate, queryContext);

export const useMailTemplateQuery = (queryContext?: GQLQueryContext<MailTemplate, MailTemplateQueryVariables>) =>
  useGQLQuery<MailTemplate, MailTemplateQueryVariables>(MailTemplateGQL.query.mailTemplate, queryContext);

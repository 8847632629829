import { SuccessCaseContentItem } from "@whyuz/services";
import { useMemo } from "react";
import { API_REST_ENDPOINT } from "@whyuz/data";

export interface SuccessCaseQuestionCardImageProps {
  question: SuccessCaseContentItem;
}

export const SuccessCaseQuestionCardImage = ({ question }: SuccessCaseQuestionCardImageProps) => {
  const asset = useMemo(() => {
    return question.assets && question.assets[0] ? question.assets[0] : undefined;
  }, [question]);

  return (
    <div className="p-5 w-[35%]">
      {asset && asset.accessToken && (
        <img
          className="w-[45%] sm:w-48 "
          key={question.title}
          src={API_REST_ENDPOINT.ASSETS + "?token=" + asset?.accessToken}
          alt={asset.originalFilename as string}
        />
      )}
    </div>
  );
};

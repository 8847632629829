import { RIGHT_BUTTON } from "@whyuz/data";
import { Collaboration, CollaborationStatus } from "@whyuz/services";
import { Button } from "flowbite-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface CollaborationCardButtonProps {
  collaboration: Collaboration;
  onButtonPressed: () => void;
}

export const CollaborationCardButton = ({ collaboration, onButtonPressed }: CollaborationCardButtonProps) => {
  const { t: tCollaboration } = useTranslation("collaboration");

  const color = useMemo(() => {
    switch (collaboration.status) {
      case CollaborationStatus.Cancelled:
        return "dark";
      case CollaborationStatus.Completed:
        return "green";
      case CollaborationStatus.Declined:
        return "dark";
      case CollaborationStatus.InProgress:
        return "primary";
      case CollaborationStatus.PendingToBeAccepted:
      default:
        return "primary";
    }
  }, [collaboration.status]);

  return (
    <Button
      color={color}
      size="lg"
      onPointerDown={(e: React.PointerEvent) => {
        if (e.button !== RIGHT_BUTTON) {
          onButtonPressed();
        }
      }}>
      <div className="flex items-center space-x-2">{tCollaboration("buttons.enter")}</div>
    </Button>
  );
};

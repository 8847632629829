import { HandThumbUpIcon } from "@heroicons/react/24/outline";
import { PlayIcon } from "@heroicons/react/24/solid";
import {
  ApprovalFlowExecutionMailMessageModal,
  isAValidReward,
  SuccessCaseApprovalFlowAddOrEdit,
} from "@whyuz/app-components";
import { Button, Error404PageNotFound, Page, useModal, useWebPageNavigation } from "@whyuz/components";
import { WHYUZ_DYNAMIC_FRONTEND_URL, WHYUZ_URL_PARAMETERS } from "@whyuz/data";
import {
  ApprovalFlowExecution,
  ApprovalFlowExecutionStatus,
  GQLError,
  GQLErrorStatusCode,
  SuccessCase,
  SuccessCaseStatus,
  useAddCustomerAppFlowToSuccessCaseMutation,
  useCollaboratorFinishTheSuccessCaseWithoutApprovalMutation,
  useCollaboratorNeedsContentApprovalSuccessCaseMutation,
  useCollaboratorSuccessCaseLazyQuery,
  useDeleteCustomerAppFlowToSuccessCaseMutation,
  useLaunchApprovalFlowExecutionMutation,
  useLaunchCustomerSuccessCaseAppFlowMutation,
  useUser,
} from "@whyuz/services";
import { Modal, Tabs } from "flowbite-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { CollaborationSuccessCaseQuestions } from "./components/CollaborationSuccessQuestions.tsx";
import { DecodedToken } from "../UserApproval/UserApprovalManager.tsx";
import { notifyError, notifyInfo } from "@whyuz/utils";
import { CollaborationSuccessCaseRewards } from "./components/CollaborationSuccessCaseRewards.tsx";
import { SuccessCaseContentEditorFinishModal } from "./modal/SuccessCaseContentEditorFinishModal.tsx";

export interface CollaborationSuccessCaseManagerProps {
  token?: DecodedToken;
}

export const CollaborationSuccessCaseManager = ({ token }: CollaborationSuccessCaseManagerProps) => {
  const { t } = useTranslation();
  const { t: tCollaboration } = useTranslation("collaboration");
  const { t: tSuccessCase } = useTranslation("successCaseTemplate");
  const { t: tApproval } = useTranslation("approval");
  const { t: tSuccessCaseContentEditor } = useTranslation("successcasecontenteditor");
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams({ [WHYUZ_URL_PARAMETERS.TAB]: "0" });
  const tenantId = searchParams.get(WHYUZ_URL_PARAMETERS.TENANT_ID);
  const activeTab = searchParams.get(WHYUZ_URL_PARAMETERS.TAB);
  const userCtx = useUser();
  const [error, setError] = useState<GQLError>();
  const [successCase, setSuccessCase] = useState<SuccessCase>();
  const [successCaseCollaborationLazyQuery, { hasEverBeenExecuted, isLoading }] = useCollaboratorSuccessCaseLazyQuery();
  const [customerSuccessCaseApprovalFlow, setCustomerSuccessCaseApprovalFlow] = useState<
    ApprovalFlowExecution | undefined
  >();
  const [customerContentSuccessCaseApprovalFlow, setCustomerContentSuccessCaseApprovalFlow] = useState<
    ApprovalFlowExecution | undefined
  >();
  const { navigate } = useWebPageNavigation();

  const {
    showModal: showApprovalInProgressModal,
    closeModal: closeApprovalInProgressModal,
    modalProps: approvalInProgressModalProps,
  } = useModal();

  const [addCustomerApprovalFlow] = useAddCustomerAppFlowToSuccessCaseMutation();
  const [deleteCustomerApprovalFlow] = useDeleteCustomerAppFlowToSuccessCaseMutation();
  const [launchApprovalSuccessCaseMutation] = useLaunchCustomerSuccessCaseAppFlowMutation();
  const [launchContentApprovalSuccessCaseMutation] = useLaunchApprovalFlowExecutionMutation();
  const [collaboratorNeedContentApprovalMutation] = useCollaboratorNeedsContentApprovalSuccessCaseMutation();
  const [collaboratorFinishWithoutApprovalMutation] = useCollaboratorFinishTheSuccessCaseWithoutApprovalMutation();
  useEffect(() => {
    if ((id && tenantId) || token) {
      const variables = {
        id: id ? id : token?.entityId,
        tenantId: tenantId ? tenantId : token?.tenantId,
      };
      successCaseCollaborationLazyQuery({ variables })
        .then((successCase) => {
          if (successCase) {
            setSuccessCase(successCase);
            if (successCase.customerApprovalFlowExecution) {
              setCustomerSuccessCaseApprovalFlow(successCase.customerApprovalFlowExecution);
            }
            if (successCase.customerContentApprovalFlowExecution) {
              setCustomerContentSuccessCaseApprovalFlow(successCase.customerContentApprovalFlowExecution);
            }
          } else {
            setError({
              isUncontrolledError: true,
              statusCode: GQLErrorStatusCode.ENTITY_NOT_FOUND,
              message: tSuccessCase("successCaseTemplate") + " " + String(id),
              fieldErrors: {},
            });
          }
        })
        .catch((error: GQLError) => setError(error));
    }
  }, [successCaseCollaborationLazyQuery, id, tenantId, tSuccessCase, token]);

  if (!(id && tenantId) && !token) {
    return <Error404PageNotFound />;
  }

  if (hasEverBeenExecuted && (!successCase || (userCtx.isUserSuperAdmin && successCase.deleted))) {
    return <Error404PageNotFound />;
  }
  const onAddSuccessCaseApprovalFlow = () => {
    addCustomerApprovalFlow({
      variables: {
        id: successCase?.id as string,
        tenantId: tenantId ? tenantId : token?.tenantId,
      },
    })
      .then((res) => {
        setCustomerSuccessCaseApprovalFlow(res.customerApprovalFlowExecution as ApprovalFlowExecution);
      })
      .catch(() => {
        notifyError(t("errors.updatingentity"));
      });
  };

  const onDeleteSuccessCaseApprovalFlow = () => {
    deleteCustomerApprovalFlow({
      variables: {
        id: successCase?.id as string,
        tenantId: tenantId ? tenantId : token?.tenantId,
      },
    })
      .then(() => {
        setCustomerSuccessCaseApprovalFlow(undefined);
      })
      .catch(() => {
        notifyError(t("errors.deletingentity"));
      });
  };

  const onLaunchSuccessCaseContentApprovalFlow = () => {
    launchContentApprovalSuccessCaseMutation({
      variables: {
        id: customerContentSuccessCaseApprovalFlow?.id as string,
        tenantId: tenantId ? tenantId : token?.tenantId,
      },
    })
      .then((res) => {
        setCustomerContentSuccessCaseApprovalFlow(res);
        notifyInfo(tApproval("launchedapproval"));
      })
      .catch(() => {
        notifyError(tApproval("errors.launchingapproval"));
      });
  };
  const launchCustomerAppFlow = () => {
    launchApprovalSuccessCaseMutation({
      variables: { id: successCase?.id as string, tenantId: tenantId ? tenantId : token?.tenantId },
    })
      .then((res) => {
        notifyInfo(tApproval("launchedapproval"));
        setCustomerSuccessCaseApprovalFlow(res.customerApprovalFlowExecution as ApprovalFlowExecution);
      })
      .catch((deleteError: GQLError) => {
        setError(deleteError);
        notifyError(tApproval("errors.launchingapproval"));
      });
  };

  const handlerCollaboratorFinishWithoutApproval = () => {
    collaboratorFinishWithoutApprovalMutation({
      variables: { id: successCase?.id as string, tenantId },
    })
      .then((res) => {
        setSuccessCase({ ...successCase, ...res });
        setCustomerContentSuccessCaseApprovalFlow(res.customerContentApprovalFlowExecution as ApprovalFlowExecution);
        notifyInfo(tSuccessCaseContentEditor("messages.succeed.finishsuccesscase"));
      })
      .catch(() => {
        notifyError(tSuccessCaseContentEditor("messages.error.general"));
      });
  };

  const handlerCollaboratorNeedsContentApproval = () => {
    collaboratorNeedContentApprovalMutation({
      variables: { id: successCase?.id as string, tenantId },
    })
      .then((res) => {
        setCustomerContentSuccessCaseApprovalFlow(res.customerContentApprovalFlowExecution as ApprovalFlowExecution);
        notifyInfo(tSuccessCaseContentEditor("messages.succeed.contentapprovalcreated"));
      })
      .catch(() => {
        notifyError(tSuccessCaseContentEditor("messages.error.general"));
      });
  };

  const onEditContent = () => {
    if (customerSuccessCaseApprovalFlow?.id && successCase?.status !== SuccessCaseStatus.InProgress) {
      showApprovalInProgressModal();
    } else {
      navigate(WHYUZ_DYNAMIC_FRONTEND_URL.successCaseContentEditor.view(successCase?.id as string, tenantId as string));
    }
  };

  return (
    <Page.LoadingOrError isLoading={isLoading} error={error} onErrorClose={() => setError(undefined)}>
      {approvalInProgressModalProps.show && (
        <Modal popup size="md" {...approvalInProgressModalProps}>
          <Modal.Header />
          <Modal.Body>
            <div className="text-center ">
              <h3 className="text-lg mb-1 font-normal text-gray-500 dark:text-gray-400">
                {tSuccessCase("sendinvitationappflowinprogress")}
              </h3>
              <h1 className="text-gray-400 mb-5"> {tSuccessCase("sendinvitationappflowinprogressdetails")}</h1>
              <div className="flex justify-center gap-4">
                <Button
                  type="button"
                  size="sm"
                  color="green"
                  breakpointSM={false}
                  onPointerDown={() => {
                    navigate(
                      WHYUZ_DYNAMIC_FRONTEND_URL.successCaseContentEditor.view(
                        successCase?.id as string,
                        tenantId as string,
                      ),
                    );
                    closeApprovalInProgressModal();
                  }}
                  leftIcon={<PlayIcon className="h-4 w-4 stroke-2 self-center" />}>
                  {tCollaboration("buttons.goedition")}
                </Button>
                <Button color="gray" onPointerDown={closeApprovalInProgressModal}>
                  {t("buttons.cancel")}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {successCase && (
        <Page>
          <Page.NavigationPanel requestDiscardChanges={false} />
          <Page.TitlePanel
            icon={<HandThumbUpIcon className="w-4 h-4 text-primary-600" />}
            title={tSuccessCase("successCase")}
            detail={successCase.title}>
            {customerSuccessCaseApprovalFlow?.id &&
              customerSuccessCaseApprovalFlow.status === ApprovalFlowExecutionStatus.NotLaunched && (
                <ApprovalFlowExecutionMailMessageModal
                  onAccept={launchCustomerAppFlow}
                  approvalFlow={customerSuccessCaseApprovalFlow}
                  tenantId={tenantId as string}
                  key={(customerSuccessCaseApprovalFlow?.status as string) + customerSuccessCaseApprovalFlow?.id}
                />
              )}
            {successCase.status === SuccessCaseStatus.PendingCustomerContentApproval && (
              <SuccessCaseContentEditorFinishModal
                successCaseMainContactEmail={successCase.mainContact?.mail ?? undefined}
                onFinish={handlerCollaboratorFinishWithoutApproval}
                onNeedContentApproval={handlerCollaboratorNeedsContentApproval}
                contentApprovalInCourse={customerContentSuccessCaseApprovalFlow}
              />
            )}
            {successCase.status === SuccessCaseStatus.CustomerInvited && !customerSuccessCaseApprovalFlow?.id && (
              <Button
                leftIcon={<HandThumbUpIcon className="h-4 w-4 stroke-2 self-center" />}
                type="button"
                size="sm"
                breakpointSM={false}
                onPointerDown={onAddSuccessCaseApprovalFlow}>
                {tCollaboration("buttons.customerapproval")}
              </Button>
            )}
            <Button
              leftIcon={<PlayIcon className="h-4 w-4 stroke-2 self-center" />}
              type="button"
              size="sm"
              color="green"
              breakpointSM={false}
              onPointerDown={onEditContent}>
              {tCollaboration("buttons.goedition")}
            </Button>
            {customerContentSuccessCaseApprovalFlow?.id &&
              customerContentSuccessCaseApprovalFlow?.status === ApprovalFlowExecutionStatus.NotLaunched && (
                <Button
                  leftIcon={<HandThumbUpIcon className="h-4 w-4 stroke-2 self-center" />}
                  classname="mr-2"
                  type="button"
                  size="sm"
                  key={
                    (customerContentSuccessCaseApprovalFlow?.status as string) +
                    customerContentSuccessCaseApprovalFlow?.id
                  }
                  breakpointSM={false}
                  onPointerDown={onLaunchSuccessCaseContentApprovalFlow}>
                  {tApproval("launchapprovalflow")}
                </Button>
              )}
          </Page.TitlePanel>
          <Page.FormContainer>
            <Tabs
              style="underline"
              onActiveTabChange={(tabNumber) =>
                setSearchParams(
                  (prev) => {
                    prev.set(WHYUZ_URL_PARAMETERS.TAB, String(tabNumber));
                    return prev;
                  },
                  { replace: true },
                )
              }>
              <Tabs.Item
                active={activeTab === "0"}
                title={tSuccessCase("successcasesubforms.questions.questionsexternal")}>
                <CollaborationSuccessCaseQuestions successCase={successCase} />
              </Tabs.Item>
              {customerSuccessCaseApprovalFlow?.id && (
                <Tabs.Item
                  translate="no"
                  active={activeTab === "1"}
                  title={tSuccessCase("successcasesubforms.customerapprovalflow.collabtitle")}>
                  <SuccessCaseApprovalFlowAddOrEdit
                    key={(customerSuccessCaseApprovalFlow?.status as string) + customerSuccessCaseApprovalFlow?.id}
                    onAddSuccessCaseApprovalFlow={onAddSuccessCaseApprovalFlow}
                    onDeleteSuccessCaseApprovalFlow={onDeleteSuccessCaseApprovalFlow}
                    internal={false}
                    successCaseAppFlowId={customerSuccessCaseApprovalFlow?.id ?? undefined}
                    tenantId={tenantId ?? token?.tenantId}
                    addFunctionsEnabled={successCase.status === SuccessCaseStatus.CustomerInvited}
                  />
                </Tabs.Item>
              )}
              {(isAValidReward(successCase.mainContactReward ?? undefined) ||
                isAValidReward(successCase.customerReward ?? undefined)) && (
                <Tabs.Item
                  translate="no"
                  active={activeTab === "2"}
                  title={tSuccessCase("successcasesubforms.rewards.title")}>
                  <CollaborationSuccessCaseRewards successCase={successCase} />
                </Tabs.Item>
              )}
              {customerContentSuccessCaseApprovalFlow?.id && (
                <Tabs.Item
                  translate="no"
                  active={activeTab === "3"}
                  title={tSuccessCase("successcasesubforms.customerapprovalflow.collabcontentapproval")}>
                  <SuccessCaseApprovalFlowAddOrEdit
                    key={
                      (customerContentSuccessCaseApprovalFlow?.status as string) +
                      customerContentSuccessCaseApprovalFlow?.id
                    }
                    onAddSuccessCaseApprovalFlow={onAddSuccessCaseApprovalFlow}
                    onDeleteSuccessCaseApprovalFlow={onDeleteSuccessCaseApprovalFlow}
                    internal={false}
                    successCaseAppFlowId={customerContentSuccessCaseApprovalFlow?.id ?? undefined}
                    tenantId={tenantId ?? token?.tenantId}
                    addFunctionsEnabled={successCase.status === SuccessCaseStatus.PendingCustomerContentApproval}
                  />
                </Tabs.Item>
              )}
            </Tabs>
          </Page.FormContainer>
        </Page>
      )}
    </Page.LoadingOrError>
  );
};

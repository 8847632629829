import { ComboboxAutocomplete, ComboboxAutocompleteProps, ComboboxItemDictionary } from "@whyuz/components";
import { Language } from "@whyuz/services";
import { useCallback, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useLanguages } from "../hooks";

export interface LanguageComboboxProps extends Omit<ComboboxAutocompleteProps<Language>, "items"> {
  showFlags?: boolean;
  languageToHide?: Language[];
}

export const LanguageCombobox = ({ showFlags = true, languageToHide, ...props }: LanguageComboboxProps) => {
  const [languageItems, setLanguageItems] = useState<ComboboxItemDictionary<Language>>({});
  const { searchLanguages, languageTranslation } = useLanguages();

  const onLanguageSearchTextChange = useCallback(
    (searchText: string | null) => {
      const languagesFound = searchLanguages(searchText);
      const items: ComboboxItemDictionary<Language> = languagesFound
        .filter((language) => !languageToHide?.map((el) => el.id).includes(language.id))
        .reduce(
          (prev, language) =>
            language
              ? {
                  ...prev,
                  [language.id]: {
                    id: language.id,
                    name: languageTranslation(language),
                    icon:
                      showFlags && language.flagCode ? (
                        <ReactCountryFlag
                          svg
                          countryCode={language.flagCode}
                          style={{ width: "1.25rem", height: "1.25rem" }}
                        />
                      ) : undefined,
                    entity: language,
                  },
                }
              : prev,
          {} as ComboboxItemDictionary<Language>,
        );
      setLanguageItems(items);
    },
    [languageTranslation, searchLanguages, showFlags],
  );

  return <ComboboxAutocomplete items={languageItems} onSearchTextChange={onLanguageSearchTextChange} {...props} />;
};

import { useMemo } from "react";
import { AssignedRewardStatus, BenefitRewardType } from "@whyuz/services";
import { useTranslation } from "react-i18next";
import { FlowbiteColors } from "flowbite-react";

export interface BenefitRewardTypeEntity {
  id: BenefitRewardType;
  name: string;
  color: keyof FlowbiteColors;
}

export interface AssignedRewardStatusEntity {
  id: AssignedRewardStatus;
  name: string;
  color: keyof FlowbiteColors;
}

export const useBenefitRewardTypesTranslations = (): Record<BenefitRewardType, BenefitRewardTypeEntity> => {
  const { t: tBenefit } = useTranslation("benefitReward");
  return useMemo(() => {
    return {
      [BenefitRewardType.CustomBenefit]: {
        id: BenefitRewardType.CustomBenefit,
        name: tBenefit("benefitrewardtypes.CUSTOM_BENEFIT"),
        color: "blue",
      },
      [BenefitRewardType.CustomReward]: {
        id: BenefitRewardType.CustomReward,
        name: tBenefit("benefitrewardtypes.CUSTOM_REWARD"),
        color: "lime",
      },
      [BenefitRewardType.GiftCard]: {
        id: BenefitRewardType.GiftCard,
        name: tBenefit("benefitrewardtypes.GIFT_CARD"),
        color: "yellow",
      },
      [BenefitRewardType.Discount]: {
        id: BenefitRewardType.Discount,
        name: tBenefit("benefitrewardtypes.DISCOUNT"),
        color: "purple",
      },
    };
  }, [tBenefit]);
};

export const useAssignedRewardStatusTranslations = (): Record<AssignedRewardStatus, AssignedRewardStatusEntity> => {
  const { t: tBenefit } = useTranslation("benefitReward");
  return useMemo(() => {
    return {
      [AssignedRewardStatus.Proposal]: {
        id: AssignedRewardStatus.Proposal,
        name: tBenefit("assignedrewardsstatus.PROPOSAL"),
        color: "purple",
      },
      [AssignedRewardStatus.Granted]: {
        id: AssignedRewardStatus.Granted,
        name: tBenefit("assignedrewardsstatus.GRANTED"),
        color: "blue",
      },
      [AssignedRewardStatus.Delivered]: {
        id: AssignedRewardStatus.Delivered,
        name: tBenefit("assignedrewardsstatus.DELIVERED"),
        color: "green",
      },
    };
  }, [tBenefit]);
};

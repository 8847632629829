import { useMemo } from "react";
import { ApprovalFlowType } from "@whyuz/services";
import { useTranslation } from "react-i18next";

export interface ApprovalFlowTypeEntity {
  id: ApprovalFlowType;
  name: string;
}

export const useApprovalFlowTypesTranslations = (): Record<ApprovalFlowType, ApprovalFlowTypeEntity> => {
  const { t: tApproval } = useTranslation("approval");
  return useMemo(() => {
    return {
      [ApprovalFlowType.SuccessCaseContents]: {
        id: ApprovalFlowType.SuccessCaseContents,
        name: tApproval("approvalflowtypes.SUCCESS_CASE_CONTENTS"),
      },
      [ApprovalFlowType.SuccessCaseCustomerContents]: {
        id: ApprovalFlowType.SuccessCaseCustomerContents,
        name: tApproval("approvalflowtypes.SUCCESS_CASE_CUSTOMER_CONTENTS"),
      },
      [ApprovalFlowType.SuccessCaseExecution]: {
        id: ApprovalFlowType.SuccessCaseExecution,
        name: tApproval("approvalflowtypes.SUCCESS_CASE_EXECUTION"),
      },
      [ApprovalFlowType.SuccessCaseCustomerParticipation]: {
        id: ApprovalFlowType.SuccessCaseCustomerParticipation,
        name: tApproval("approvalflowtypes.SUCCESS_CASE_CUSTOMER_PARTICIPATION"),
      },
    };
  }, [tApproval]);
};

import { API_REST_ENDPOINT } from "@whyuz/data";
import { Collaboration } from "@whyuz/services";

export interface CollaborationCardHeaderProps {
  collaboration: Collaboration;
}

export const CollaborationCardHeader = ({ collaboration }: CollaborationCardHeaderProps) => {
  return (
    <div className="flex items-center justify-center p-5 bg-primary-50 dark:bg-primary-900 h-48 max-h-48 space-x-4">
      {collaboration.customerLogoAssetToken ? (
        <img
          className="w-[45%] sm:w-48 px-4"
          src={API_REST_ENDPOINT.ASSETS + "?token=" + collaboration.customerLogoAssetToken}
          alt="customer logo"
        />
      ) : (
        <p className="w-[45%] sm:w-48 text-2xl sm:text-4xl font-semibold overflow-hidden text-ellipsis select-none">
          {collaboration.customerName}
        </p>
      )}
      <span className="text-2xl font-semibold text-primary-400 dark:text-primary-300 select-none">&</span>
      {collaboration.tenant?.logoAssetToken ? (
        <img
          className="w-[45%] sm:w-48 px-4"
          src={API_REST_ENDPOINT.ASSETS + "?token=" + collaboration.tenant?.logoAssetToken}
          alt="company logo"
        />
      ) : (
        <p className="w-[45%] sm:w-48 text-2xl sm:text-4xl font-semibold overflow-hidden text-ellipsis select-none">
          {collaboration.tenant?.name}
        </p>
      )}
    </div>
  );
};

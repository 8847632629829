import { AdvancedTable, Card, Text, useAdvancedTable } from "@whyuz/components";
import { ApprovalFlowExecutionStep, ApprovalFlowExecutionUser, GQLSort, useUser } from "@whyuz/services";
import { Label } from "flowbite-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  filterAndSortAppFlowUsersToShow,
  useApprovalFlowTemplateUsersTableConfiguration,
} from "../ApprovalFlows/hooks/useApprovalFlowTemplateUsersTableConfiguration.tsx";

interface ApprovalFlowExecutionStepCardProps {
  approvalFlowExecutionStep: ApprovalFlowExecutionStep;
}
export const ApprovalFlowExecutionStepCard = ({ approvalFlowExecutionStep }: ApprovalFlowExecutionStepCardProps) => {
  const { t: tApproval } = useTranslation("approval");
  const userCtx = useUser();

  const approvalFlowUserTableConfiguration =
    useApprovalFlowTemplateUsersTableConfiguration<ApprovalFlowExecutionUser>();
  const approvalFlowUserTableState = useAdvancedTable<ApprovalFlowExecutionUser, ApprovalFlowExecutionUser>({
    tableConfiguration: approvalFlowUserTableConfiguration,
  });

  const approvalFlowUserToShow = useMemo(
    () =>
      filterAndSortAppFlowUsersToShow<ApprovalFlowExecutionUser>(
        approvalFlowExecutionStep.users as ApprovalFlowExecutionUser[],
        approvalFlowUserTableState.filters,
        userCtx.isUserSuperAdmin,
        approvalFlowUserTableState.orderBy as GQLSort<ApprovalFlowExecutionUser>,
      ),
    [
      approvalFlowExecutionStep.users,
      approvalFlowUserTableState.filters,
      approvalFlowUserTableState.orderBy,
      userCtx.isUserSuperAdmin,
    ],
  );

  return (
    <Card className="cursor-auto">
      <div className="flex flex-col w-full">
        <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center">
          <div className="w-full flex flex-col md:flex-row items-start md:items-center space-x-0 md:space-x-2">
            <Text className="text-gray-500 dark:text-gray-400">
              {tApproval("numbers.approvalstepWithCount", {
                count: (approvalFlowExecutionStep.stepNumber as number) + 1,
                ordinal: true,
              })}
            </Text>
            <Label className="text-gray-500 dark:text-gray-400">
              {approvalFlowExecutionStep.requiredAll
                ? tApproval("allusersrequired")
                : tApproval("atleastoneuserrequired")}
            </Label>
          </div>
        </div>
        <div className="w-full flex flex-col gap-4 mt-4">
          <AdvancedTable
            tableState={approvalFlowUserTableState}
            entitiesToShow={approvalFlowUserToShow}
            showColumnActions={false}
          />
        </div>
      </div>
    </Card>
  );
};

import { CheckIcon, ClockIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { AdvancedTableColumnConfiguration, AdvancedTableConfiguration } from "@whyuz/components";
import {
  ApprovalFlowExecutionUser,
  ApprovalFlowStepExecutionStatus,
  ApprovalFlowTemplateUser,
  FilterExpression,
  FilterExpressionOperator,
  FilterField,
  FilterOperator,
  GQLSort,
  SortDirection,
} from "@whyuz/services";
import { DeepKeyOf, getPropertyByPath } from "@whyuz/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

export const APP_FLOW_USERS_TABLE_COLUMNS = {
  EMAIL: "appflowemail",
  STATUS: "appflowstatus",
};
export const useApprovalFlowTemplateUsersTableConfiguration = <
  T extends ApprovalFlowExecutionUser | ApprovalFlowTemplateUser,
>() => {
  const { t } = useTranslation();

  const iconBasicClass = "h-6 w-6 m-0.5 rounded-sm";
  const getApprovalFlowUserStatusColor = (status: ApprovalFlowStepExecutionStatus) => {
    switch (status) {
      case ApprovalFlowStepExecutionStatus.Approved:
        return <CheckIcon className={twMerge(iconBasicClass, "text-green-600")} />;
      case ApprovalFlowStepExecutionStatus.Pending:
        return <ClockIcon className={twMerge(iconBasicClass, "text-primary-600")} />;
      case ApprovalFlowStepExecutionStatus.Rejected:
        return <XCircleIcon className={twMerge(iconBasicClass, "text-red-600")} />;
    }
    return "-";
  };

  // @ts-ignore
  const columnUserEmail: AdvancedTableColumnConfiguration<T, T> = useMemo(() => {
    return {
      columnKey: APP_FLOW_USERS_TABLE_COLUMNS.EMAIL,
      entityField: "email" as DeepKeyOf<T>,
      columnName: t("word.email"),
      position: 1,
      isVisible: true,
      isMobileVisible: true,
      boldText: true,
      enableFilter: true,
      className: "w-[5rem] md:w-full truncate",
    };
  }, [t]);

  // @ts-ignore
  const columnUserStatus: AdvancedTableColumnConfiguration<T, T> = useMemo(() => {
    return {
      columnKey: APP_FLOW_USERS_TABLE_COLUMNS.STATUS,
      entityField: "status" as DeepKeyOf<T>,
      columnName: t("word.status"),
      position: 1,
      isVisible: true,
      isMobileVisible: true,
      boldText: true,
      enableFilter: true,
      className: "w-[5rem] md:w-full",
      cellContent: (stepUser: ApprovalFlowExecutionUser) =>
        getApprovalFlowUserStatusColor(stepUser.status as ApprovalFlowStepExecutionStatus),
    };
  }, [t]);

  const tableColumnsConfiguration: AdvancedTableColumnConfiguration<T, T>[] = useMemo(
    (): AdvancedTableColumnConfiguration<T, T>[] => [columnUserEmail, columnUserStatus],
    [columnUserEmail, columnUserStatus],
  );

  const tableConfiguration: AdvancedTableConfiguration<T, T> = useMemo(() => {
    return {
      title: t("word.users"),
      entityNameSingular: t("word.user"),
      entityNamePlural: t("word.users"),
      entityDescription: (approvalFlowTemplateUser: T) => approvalFlowTemplateUser.email ?? "",
      enableColumnManagement: true,
      enableMultipleSelection: false,
      showRowActions: true,
      cleanSelectedEntitiesOnFilterChange: false,
      cleanSelectedEntitiesOnSearchChange: false,
      columnsDefinition: tableColumnsConfiguration,
    };
  }, [t, tableColumnsConfiguration]);

  return tableConfiguration;
};

export const AppFlowUsersFilterExpression = (filters: ApprovalFlowExecutionUser | ApprovalFlowTemplateUser) => {
  const filterFields: FilterField[] = [];
  const filterExpressions: FilterExpression[] = [];

  if (filters.id) {
    filterFields.push({
      field: "id",
      operator: FilterOperator.Like,
      value: "%" + String(filters.id) + "%",
    });
  }

  if (filters.email) {
    filterFields.push({
      field: "email",
      operator: FilterOperator.Like,
      value: "%" + filters.email + "%",
    });
  }

  return filterExpressions.length > 0 || filterFields.length > 0
    ? ({
        operator: FilterExpressionOperator.And,
        fields: filterFields.length > 0 ? filterFields : undefined,
        expressions: filterExpressions.length > 0 ? filterExpressions : undefined,
      } as FilterExpression)
    : undefined;
};

export const filterAndSortAppFlowUsersToShow = <U extends ApprovalFlowExecutionUser | ApprovalFlowTemplateUser>(
  users: U[] | undefined,
  filters: U | undefined,
  includeDeleted: boolean,
  orderBy: GQLSort<U> | undefined,
): U[] =>
  [...(users ?? [])]
    .filter(
      (actUser) =>
        (includeDeleted || !actUser.deleted) &&
        (!filters ||
          ((!filters.id || String(actUser.id).toLowerCase().includes(String(filters.id).toLowerCase())) &&
            (!filters.email || actUser.email?.toLowerCase().includes(filters.email.toLowerCase())))),
    )
    .sort((user1, user2) =>
      orderBy?.field
        ? String(getPropertyByPath(user1, orderBy.field as string)).localeCompare(
            String(getPropertyByPath(user2, orderBy.field as string)),
          ) < 0
          ? orderBy.sortDirection === SortDirection.Asc
            ? 1
            : -1
          : orderBy.sortDirection === SortDirection.Asc
            ? -1
            : 1
        : user1?.createdOn && user2?.createdOn
          ? String(user1.createdOn).localeCompare(String(user2.createdOn))
          : 1,
    );

import { ApprovalFlowExecutionBuilderProvider } from "../../../ApprovalFlowExecutionBuilder";
import { ApprovalFlowExecutionCore } from "../../../ApprovalFlowExecutionBuilder/ApprovalFlowExecutionCore.tsx";
import { AddApprovalFlowToSuccessCase } from "./AddApprovalFlow.tsx";

export interface SuccessCaseApprovalFlowAddOrEditProps {
  successCaseAppFlowId?: string;
  onAddSuccessCaseApprovalFlow?: (approvalFlowTemplateId?: string) => void;
  onDeleteSuccessCaseApprovalFlow?: () => void;
  internal: boolean;
  tenantId?: string;
  addFunctionsEnabled: boolean;
}
export const SuccessCaseApprovalFlowAddOrEdit = ({
  successCaseAppFlowId,
  onDeleteSuccessCaseApprovalFlow,
  onAddSuccessCaseApprovalFlow,
  internal,
  tenantId,
  addFunctionsEnabled,
}: SuccessCaseApprovalFlowAddOrEditProps) => {
  return successCaseAppFlowId ? (
    <ApprovalFlowExecutionBuilderProvider approvalFlowExecutionId={successCaseAppFlowId} tenantId={tenantId}>
      <ApprovalFlowExecutionCore showDeleteButton onDelete={onDeleteSuccessCaseApprovalFlow} />
    </ApprovalFlowExecutionBuilderProvider>
  ) : (
    <AddApprovalFlowToSuccessCase
      onAddSuccessCaseApprovalFlow={onAddSuccessCaseApprovalFlow}
      internal={internal}
      addFunctionsEnabled={addFunctionsEnabled}
    />
  );
};

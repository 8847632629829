import { ComboboxAutocomplete, ComboboxAutocompleteProps, ComboboxItemDictionary } from "@whyuz/components";
import {
  FilterExpression,
  FilterExpressionOperator,
  FilterField,
  FilterOperator,
  GQLError,
  MailTemplate,
  MailTemplatePage,
  MailTemplateType,
  SortDirection,
  useMailTemplatesLazyQuery,
} from "@whyuz/services";
import { getColSpan, isEmpty, notifyError } from "@whyuz/utils";
import { HelperText } from "flowbite-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export interface MailTemplageComboboxProps extends Omit<ComboboxAutocompleteProps<MailTemplate>, "items"> {
  mailTemplateType: MailTemplateType;
}

export const MailTemplateCombobox = ({
  mailTemplateType,
  columns,
  columnsBreakpointSM,
  ...props
}: MailTemplageComboboxProps) => {
  const { t } = useTranslation();
  const [internalError, setInternalError] = useState<GQLError>();
  const [mailTemplateItems, setMailTemplateItems] = useState<ComboboxItemDictionary<MailTemplate>>({});
  const [mailTemplatesQuery, { isLoading }] = useMailTemplatesLazyQuery();

  const onSearchTextChange = useCallback(
    (searchText: string | null) => {
      const filterFields: FilterField[] = [{ field: "type", operator: FilterOperator.Equal, value: mailTemplateType }];
      const expressions: FilterExpression[] = [];

      if (!isEmpty(searchText)) {
        expressions.push({
          operator: FilterExpressionOperator.Or,
          fields: [
            { field: "id", operator: FilterOperator.Like, value: "%" + searchText + "%" },
            { field: "name", operator: FilterOperator.Like, value: "%" + searchText + "%" },
          ],
        });
      }

      const filterExpression =
        filterFields.length > 0 || expressions.length > 0
          ? ({
              operator: FilterExpressionOperator.And,
              fields: filterFields,
              expressions: expressions,
            } as FilterExpression)
          : undefined;

      mailTemplatesQuery({
        variables: {
          filterExpression,
          pageSize: 20,
          sortField: "name",
          sortDirection: SortDirection.Asc,
        },
      })
        .then((page: MailTemplatePage) => {
          const selectedItemDictionary: ComboboxItemDictionary<MailTemplate> = {};
          const items: ComboboxItemDictionary<MailTemplate> = page?.content
            ? page.content.reduce(
                (prev, mailTemplate) =>
                  mailTemplate
                    ? {
                        ...prev,
                        [mailTemplate.id]: {
                          id: mailTemplate.id as string,
                          name: mailTemplate.name,
                          entity: mailTemplate,
                        },
                      }
                    : prev,
                selectedItemDictionary,
              )
            : {};
          setMailTemplateItems(items);
          setInternalError(undefined);
        })
        .catch((usersError: GQLError) => {
          setInternalError(usersError);
          notifyError(t("errors.nomatchesfound"));
        });
    },
    [mailTemplateType, mailTemplatesQuery, t],
  );

  return (
    <div
      className={`flex flex-col ${getColSpan(columns, columnsBreakpointSM) ?? ""} ${props.fullWidth ? "w-full" : ""} space-y-3`}>
      <ComboboxAutocomplete
        items={mailTemplateItems}
        onSearchTextChange={onSearchTextChange}
        isLoading={isLoading}
        {...props}
      />
      {internalError && <HelperText color="failure">{internalError.message}</HelperText>}
    </div>
  );
};

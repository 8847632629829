import { useGQLLazyQuery, useGQLMutation, useGQLQuery } from "../";
import { GQLQueryContext } from "../../types";

import { SuccessCaseGQL } from "../../queries";
import {
  AddCustomerAppFlowToSuccessCaseMutationVariables,
  AddInternalAppFlowToSuccessCaseMutationVariables,
  AddSuccessCaseMutationVariables,
  CancelSuccessCaseMutationVariables,
  CopySuccessCaseFromTemplateMutationVariables,
  DeleteCustomerAppFlowToSuccessCaseMutationVariables,
  DeleteInternalAppFlowToSuccessCaseMutationVariables,
  DeleteSuccessCaseMutationVariables,
  DraftStatusSuccessCaseMutationVariables,
  LaunchSuccessCaseInternalApprovalFlowMutationVariables,
  ResendSuccessCaseInvitationMutationVariables,
  SendSuccessCaseInvitationMutationVariables,
  SuccessCase,
  SuccessCaseAcceptReviewMutationVariables,
  SuccessCaseAskForChangesMutationVariables,
  SuccessCasePage,
  SuccessCaseQueryVariables,
  SuccessCasesQueryVariables,
  UpdateSuccessCaseMutationVariables,
} from "../../codegen/graphql";

export const useAddSuccessCaseMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, AddSuccessCaseMutationVariables>,
) =>
  useGQLMutation<SuccessCase, AddSuccessCaseMutationVariables>(SuccessCaseGQL.mutation.addSuccessCase, mutationContext);

export const useSendSuccessCaseInvitationMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, SendSuccessCaseInvitationMutationVariables>,
) =>
  useGQLMutation<SuccessCase, SendSuccessCaseInvitationMutationVariables>(
    SuccessCaseGQL.mutation.sendSuccessCaseInvitation,
    mutationContext,
  );

export const useResendSuccessCaseInvitationMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, ResendSuccessCaseInvitationMutationVariables>,
) =>
  useGQLMutation<SuccessCase, ResendSuccessCaseInvitationMutationVariables>(
    SuccessCaseGQL.mutation.resendSuccessCaseInvitation,
    mutationContext,
  );

export const useLaunchSuccessCaseInternalAppFlowMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, LaunchSuccessCaseInternalApprovalFlowMutationVariables>,
) =>
  useGQLMutation<SuccessCase, LaunchSuccessCaseInternalApprovalFlowMutationVariables>(
    SuccessCaseGQL.mutation.launchSuccessCaseInternalApprovalFlow,
    mutationContext,
  );

export const useCancelSuccessCaseMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, CancelSuccessCaseMutationVariables>,
) =>
  useGQLMutation<SuccessCase, CancelSuccessCaseMutationVariables>(
    SuccessCaseGQL.mutation.cancelSuccessCase,
    mutationContext,
  );

export const useDraftSuccessCaseMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, DraftStatusSuccessCaseMutationVariables>,
) =>
  useGQLMutation<SuccessCase, DraftStatusSuccessCaseMutationVariables>(
    SuccessCaseGQL.mutation.draftStatusSuccessCase,
    mutationContext,
  );

export const useUpdateSuccessCaseMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, UpdateSuccessCaseMutationVariables>,
) =>
  useGQLMutation<SuccessCase, UpdateSuccessCaseMutationVariables>(
    SuccessCaseGQL.mutation.updateSuccessCase,
    mutationContext,
  );

export const useCopySuccessCaseFromTemplateSuccessCaseMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, CopySuccessCaseFromTemplateMutationVariables>,
) =>
  useGQLMutation<SuccessCase, CopySuccessCaseFromTemplateMutationVariables>(
    SuccessCaseGQL.mutation.copySuccessCaseFromTemplate,
    mutationContext,
  );

export const useAddInternalAppFlowToSuccessCaseMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, AddInternalAppFlowToSuccessCaseMutationVariables>,
) =>
  useGQLMutation<SuccessCase, AddInternalAppFlowToSuccessCaseMutationVariables>(
    SuccessCaseGQL.mutation.addInternalAppFlowToSuccessCase,
    mutationContext,
  );

export const useDeleteInternalAppFlowToSuccessCaseMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, DeleteInternalAppFlowToSuccessCaseMutationVariables>,
) =>
  useGQLMutation<SuccessCase, DeleteInternalAppFlowToSuccessCaseMutationVariables>(
    SuccessCaseGQL.mutation.deleteInternalAppFlowToSuccessCase,
    mutationContext,
  );
export const useAddCustomerAppFlowToSuccessCaseMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, AddCustomerAppFlowToSuccessCaseMutationVariables>,
) =>
  useGQLMutation<SuccessCase, AddCustomerAppFlowToSuccessCaseMutationVariables>(
    SuccessCaseGQL.mutation.addCustomerAppFlowToSuccessCase,
    mutationContext,
  );

export const useDeleteCustomerAppFlowToSuccessCaseMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, DeleteCustomerAppFlowToSuccessCaseMutationVariables>,
) =>
  useGQLMutation<SuccessCase, DeleteCustomerAppFlowToSuccessCaseMutationVariables>(
    SuccessCaseGQL.mutation.deleteCustomerAppFlowToSuccessCase,
    mutationContext,
  );

export const useDeleteSuccessCaseMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, DeleteSuccessCaseMutationVariables>,
) =>
  useGQLMutation<SuccessCase, DeleteSuccessCaseMutationVariables>(
    SuccessCaseGQL.mutation.deleteSuccessCase,
    mutationContext,
  );

export const useSuccessCaseAskForChangesMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, SuccessCaseAskForChangesMutationVariables>,
) =>
  useGQLMutation<SuccessCase, SuccessCaseAskForChangesMutationVariables>(
    SuccessCaseGQL.mutation.successCaseAskForChanges,
    mutationContext,
  );

export const useSuccessCaseAcceptReviewMutation = (
  mutationContext?: GQLQueryContext<SuccessCase, SuccessCaseAcceptReviewMutationVariables>,
) =>
  useGQLMutation<SuccessCase, SuccessCaseAcceptReviewMutationVariables>(
    SuccessCaseGQL.mutation.successCaseAcceptReview,
    mutationContext,
  );
export const useSuccessCasesLazyQuery = (queryContext?: GQLQueryContext<SuccessCasePage, SuccessCasesQueryVariables>) =>
  useGQLLazyQuery<SuccessCasePage, SuccessCasesQueryVariables>(SuccessCaseGQL.query.successCases, queryContext);

export const useSuccessCasesQuery = (queryContext?: GQLQueryContext<SuccessCasePage, SuccessCasesQueryVariables>) =>
  useGQLQuery<SuccessCasePage, SuccessCasesQueryVariables>(SuccessCaseGQL.query.successCases, queryContext);

export const useSuccessCaseLazyQuery = (queryContext?: GQLQueryContext<SuccessCase, SuccessCaseQueryVariables>) =>
  useGQLLazyQuery<SuccessCase, SuccessCaseQueryVariables>(SuccessCaseGQL.query.successCase, queryContext);

export const useSuccessCaseQuery = (queryContext?: GQLQueryContext<SuccessCase, SuccessCaseQueryVariables>) =>
  useGQLQuery<SuccessCase, SuccessCaseQueryVariables>(SuccessCaseGQL.query.successCase, queryContext);
